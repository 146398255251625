import { StatusBar } from 'expo-status-bar';
import { Button, StyleSheet, Text, View, TextInput,Dimensions } from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
import TouchableScale from 'react-native-touchable-scale';
import * as Progress from 'react-native-progress';


const screenWidth = Math.round(Dimensions.get('window').width);
const screenHeight = Math.round(Dimensions.get('window').height);



export default function Screen2({navigation}) {
    return (
      <View style={styles.container}>
        <View>
          <View>
            <Progress.Bar progress={0.22} width={null} color={'#a7ebb9'} borderColor={'#244761'} unfilledColor={'#000'} borderRadius={0} height={7} style={{ marginVertical:10}} />
          </View>

          <View style={{ marginVertical: 20}}>
            <Text style={{ color:'#ddd', fontSize:18, fontWeight:'bold', paddingBottom:10}}>Great! You've just taken a big step on yoiur journey</Text>
            <Text style={{ color:'#ddd', fontSize:15,}}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi non quis exercitationem culpa nesciunt nihil aut nostrum explicabo reprehenderit optio amet ab temporibus asperiores quasi cupiditate. Voluptatum ducimus voluptates voluptas?</Text>

            <Text style={{ color:'#ddd', fontSize:15,}}>Now, let's talk about your goal to gain weight.</Text>
          </View>
        </View>


        <TouchableScale style={{padding:4,backgroundColor:'#148be3',marginVertical:5,borderRadius:25, }} onPress={()=>{navigation.navigate('Screen3')}}>
            <View style={{ padding:5}}>
                <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold", textAlign:'center'}}>Next</Text>
            </View>
          </TouchableScale>
      </View>
    )
}


const styles = StyleSheet.create({
    container:{
      flex: 1,
      backgroundColor: '#000',
      padding:15
  },
})