import { StatusBar } from 'expo-status-bar';
import { Button, StyleSheet, Text, View, TextInput,Dimensions } from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
import TouchableScale from 'react-native-touchable-scale';
import * as Progress from 'react-native-progress';



const screenWidth = Math.round(Dimensions.get('window').width);
const screenHeight = Math.round(Dimensions.get('window').height);



export default function Screen8({navigation}) {
    return (
      <View style={styles.container}>
        <View >
          <View>
            <Progress.Bar progress={0.88} width={null} color={'#a7ebb9'} borderColor={'#244761'} unfilledColor={'#082032'} borderRadius={0} height={7} style={{ marginVertical:10}} />
          </View>

          <View style={{ marginVertical: 20}}>
            <Text style={{ color:'#ddd', fontSize:18, fontWeight:'bold'}}>Please select which sex we should use to calculate your caloric needs:</Text>
            <Text style={{ color:'#ddd', fontSize:13,marginTop:8}}><FontAwesome name='question' style={{fontSize:13 ,color:'#b2b1b9',flexDirection:'row',marginTop:15}}/> Which one should i choose?</Text>
          </View>

          <View>
          <View style={{ padding:4,backgroundColor:'#102a43',marginVertical:5, }}>
            <View style={{ padding:5, flexDirection:'row', justifyContent:'space-evenly'}}>
              <TouchableScale>
                <View style={{textAlign:'center', flexDirection:'row'}}>
                <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold",  borderColor:'#ddd'}}>Male</Text>
                </View>
              </TouchableScale>
              <TouchableScale>
                <View style={{textAlign:'center', flexDirection:'row'}}>
                <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold",  borderColor:'#ddd'}}>Female</Text>
                </View>
              </TouchableScale>       
              
            </View>
          </View>


        <View style={{paddingTop:30}}>
        <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold",  borderColor:'#ddd', paddingBottom:10}}>How old are you?</Text>
         <TextInput style={{padding:4,backgroundColor:'#102a43',marginVertical:5}} placeholder='Age' placeholderTextColor='#ddd' textAlign='center' color='#fff'/>
        </View>

        <View style={{paddingTop:30}}>
        <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold",  borderColor:'#fff', paddingBottom:10}}>Where do you live?</Text>
         <TextInput style={{padding:4,backgroundColor:'#102a43',marginVertical:5}} placeholder='Country' placeholderTextColor='#ddd' textAlign='center' color='#fff'/>
        </View>

          </View>
        </View>
        <TouchableScale style={{padding:4,backgroundColor:'#148be3',marginVertical:5,borderRadius:25, }} onPress={()=>{navigation.navigate('Screen9')}}>
            <View style={{ padding:5}}>
                <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold", textAlign:'center'}}>Next</Text>
            </View>
          </TouchableScale>
      </View>
    )
}


const styles = StyleSheet.create({
    container:{
      flex: 1,
      backgroundColor: '#082032',
      padding:15
  },
  templateCard:{
    padding:4,
    backgroundColor:'#102a43',
    marginVertical:5
},
})