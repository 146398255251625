import { StyleSheet, Text, View,TextInput, ScrollView,FlatList, Image , Button} from 'react-native';
import TouchableScale from 'react-native-touchable-scale';
import {FontAwesome} from '@expo/vector-icons';


export default function MeditationUnlock({navigation}) {
    return(
        <ScrollView style={styles.container}>
            <View>
                <Image 
                style={{ height:200, borderRadius:10,marginBottom:15}}
                source={{
                    uri:'https://www.verywellmind.com/thmb/MQWhY7u5mTDfaO4vgDHRvnHYcaA=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/GettyImages-938890492-becc3fc4757849bea672f148454943f9.jpg'
                }}
                />

            </View>
            <TouchableScale style={{borderColor:'#b2b1b9', borderWidth:0.2, margin:2, paddingTop:10,paddingBottom:10,borderRadius:15, backgroundColor:'#000'}}>
                <Text style={{color:'#ffa591', fontSize:30,textAlign:'center', padding:5, marginTop:10}}>FLASH SALE 50% OFF ON YEARLY AND LIFETIME SUBSCRIPTIONS</Text>
            </TouchableScale>

            <View style={{flexDirection:'row', padding:10,marginBottom:25}}>
                <TouchableScale style={{borderColor:'#000', borderWidth:1, margin:2, width:'50%',paddingTop:10,paddingBottom:10,borderRadius:15, backgroundColor:'#04543c'}}>
                    <Text style={{color:'#b2b1b9', fontSize:30,fontWeight:'bold',textAlign:'center',}}>1 YEAR</Text>
                    <Text style={{color:'#b2b1b9', fontSize:30,textAlign:'center',}}>$6.99</Text>
                    <Text style={{color:'#b2b1b9', fontSize:15,textAlign:'center',}}>A Couple of lines just for fun</Text>

                </TouchableScale>
                <TouchableScale style={{borderColor:'#000', borderWidth:1,width:'50%', margin:2, backgroundColor:'#54043b',paddingTop:10,paddingBottom:10,borderRadius:15,}}>
                    <Text style={{color:'#b2b1b9', fontSize:30,fontWeight:'bold',textAlign:'center',}}>LIFETIME</Text>
                    <Text style={{color:'#b2b1b9', fontSize:30,textAlign:'center',}}>$6.99</Text>
                    <Text style={{color:'#b2b1b9', fontSize:15,textAlign:'center',}}>A Couple of lines just for fun</Text>
                </TouchableScale>
            </View>

            {/* <View>
                <Text style={{color:'#b2b1b9', fontSize:25,fontWeight:'bold',textAlign:'center',paddingTop:10}}>Here A Couple more lines and a button underneath</Text>
                <Button style={{color:'#b2b1b9',marginLeft:20,marginTop:10}} title='Start An Empty Workout'>Start Workout</Button>

            </View> */}
        </ScrollView>
    )
}



const styles = StyleSheet.create({
    container:{
      flex: 1,
      backgroundColor: '#000',
      paddingTop:15,
      padding:5,
      
      
    
  },
})