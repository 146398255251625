
import {loadStripe} from "@stripe/stripe-js";
import { useEffect, useState } from "react";


// export const stripe = new Stripe(
//     'sk_test_51MaJKkBFWSkhrz7hv7m49c0LbtYXV2aX7jfTPSL3vvQBjjxaQYwwwX6D3h7m9ALmbfJFXgO6YibawKwgCXxXTmxI00fZDQN2DR'
//   );

const stripePromise = loadStripe('pk_test_51MRZYrJ0xw8ZEhqlMgChJJaOSVdyvIp31JlqpksOXnuPzkBRre91IHQZn3dZfxMzQ9giXAUnZm8H1ahK3FomiDj4004sUnRaU1');
  export const getStripe = (handleSetLoading) => {
    const [stripe, setStripe] = useState(null)
    // handleSetLoading(true);
    useEffect(() => {
      stripePromise.then((stripe) => {
        // console.log("hook: ", stripe)
        setStripe(stripe);
        // handleSetLoading(false);
      });
    }, []);
    
    return stripe;
  };