import { Button, StyleSheet, Text, View, TextInput,ScrollView, Image,FlatList, RefreshControl, ActivityIndicator } from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
import TouchableScale from 'react-native-touchable-scale';
import { useFonts } from 'expo-font';
// import "../../assets/fonts/Poppins.ttf"
import {AppLoading} from "expo-app-loading";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useCallback, useEffect, useState } from 'react';
import { getDiaryFullData, getFoodFullData } from '../hooks/DiaryHook';
import { currentDate, currentDateCalender, formatDate } from '../Utils/DateTime';
import { useFocusEffect, useRoute } from '@react-navigation/native';

/*
const DATA = [
    {
        name: "Breakfast",
        label:"Add Food",
        id:1
    },
    {
        name: "Lunch",
        label:"Add Food",

        id:2
    },
    {
        name: "Dinner",
        label:"Add Food",

        id:3
    },
    {
        name: "Snacks",
        label:"Add Food",

        id:4
    },
    {
        name: "Exercise",
        label:"Add Exercise",

        id:5
    },
    {
        name: "Water",
        label:"Add Water",

        id:6
    },
]

*/
// function Item({name, label, navigation}){
//     return(
        
      
//     )
//   }


export default function Diary({navigation}){
    const route = useRoute();
    const [loading, setLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);

    const [diaryDate, setDiaryDate] = useState(currentDateCalender);

    const [foodList,setFoodList] = useState([])
    // const [diaryDetails, setDiaryDetails] = useState(null);
    const [breakfastItems, setBreakfastItems] = useState(null);
    const [lunchItems, setLunchItems] = useState(null);
    const [dinnerItems, setDinnerItems] = useState(null);
    const [snacksItems, setSnacksItems] = useState(null);
    const [exerciseItems, setexerciseItems] = useState(null);

    const [goalCalories, setGoalCalories] = useState(2100);
    const [foodCalories, setFoodCalories] = useState(0);

    let [fontsLoad] = useFonts({
        Poppins : require('../../assets/fonts/Poppins.ttf'),
        Pattaya : require('../../assets/fonts/Pattaya.ttf')
    });


    const getFoodServingsCalculateByID = (serving_data, food_data) =>{
        // const findData = foodDataList.find(item=>item.id===foodID);
        if (serving_data && food_data) {
            // const caloriesForServings = parseInt(food_data.serving_size.amount); //(findData.Serving).replace("gram","").trim();  //gram
            const calories = (food_data.food_nutrients.calories) //.replace("cal","").trim() //cal
            // console.log("calories: ",calories, "caloriesForServings: ", caloriesForServings)
            // return Math.round(parseFloat((calories/caloriesForServings)*serving_data.amount));
            return Math.round(parseFloat(calories*serving_data.amount));
        }
        return null
    }
    

    const loadData =()=>{
        if (route.params?.DiaryDate) {
            setDiaryDate(route.params.DiaryDate)
        }

        getDiaryFullData((route.params?.DiaryDate)?route.params.DiaryDate:currentDateCalender).then((diaryData)=>{
            if (diaryData) {
                console.log("diaryData: ", diaryData)
                // const findData = diaryData.find(item=>item.date===currentDate);
                // if (findData) {
                    // setDiaryDetails(diaryData);
                    const diaryDetailsData = diaryData; //findData.diaryDetails;
                    
                    // getFoodFullData().then((foodData)=>{
                        // if (foodData) {
                            // setFoodList(foodData);

                            let totalFoodCalories = 0;
                            
                            const findBreakfast = diaryDetailsData.filter(item=>item.meal_type==="Breakfast")
                            // console.log("findBreakfast: ", findBreakfast)
                            if (findBreakfast.length>0) {
                                setBreakfastItems(findBreakfast)
                                findBreakfast.map(item=>{
                                    totalFoodCalories = totalFoodCalories+ getFoodServingsCalculateByID(item.serving_data, item.foods);
                                })
                            }
                            const findLunch = diaryDetailsData.filter(item=>item.meal_type==="Lunch")
                            if (findLunch.length>0) {
                                setLunchItems(findLunch)
                                findLunch.map(item=>{
                                    totalFoodCalories = totalFoodCalories+ getFoodServingsCalculateByID(item.serving_data, item.foods);
                                })
                            }
                            const findDinner = diaryDetailsData.filter(item=>item.meal_type==="Dinner")
                            if (findDinner.length>0) {
                                setDinnerItems(findDinner)
                                findDinner.map(item=>{
                                    totalFoodCalories = totalFoodCalories+ getFoodServingsCalculateByID(item.serving_data, item.foods);
                                })
                            }
                            const findSnacks = diaryDetailsData.filter(item=>item.meal_type==="Snacks")
                            if (findSnacks.length>0) {
                                setSnacksItems(findSnacks)
                                findSnacks.map(item=>{
                                    totalFoodCalories = totalFoodCalories+ getFoodServingsCalculateByID(item.serving_data, item.foods);
                                })
                            }
                            setFoodCalories(totalFoodCalories)
                            // console.log("totalFoodCalories: ", totalFoodCalories)
                        // }
                        setLoading(false);
                    // })

                    // const findExercise = diaryDetailsData.find(item=>item.mealType==="Exercise")
                    // if (findExercise) {
                    //     setexerciseItems(findDinner.foods)
                    // }
               // }
            }
            setLoading(false);
        })
        
      }

    //   const getFoodDataByID = (foodID) =>{
    //     const findData = foodList.find(item=>item.id===foodID);
    //     return (findData)?findData:null
    //   }

      

      const onRefresh = useCallback(() => {
        setRefreshing(true);
        loadData();
        // Alert.alert("Data Load Refresh","data loaded")
        setRefreshing(false)
        // wait(2000).then(() => setRefreshing(false));
    }, [refreshing]);




    useEffect(()=>{
        navigation.addListener('focus', ()=>{
            loadData()
        })
        
    },[]);

    // useFocusEffect(
    //     useCallback(()=>{
    //         loadData();
    //     },[])
    // )

    if(!fontsLoad){
        return null;
    }

    return(
        <View style={styles.container} >
            <View style={{backgroundColor:'#183c57', padding:10,marginBottom:20, borderRadius:10}}>
                <View>{(loading===true)?(<ActivityIndicator/>):(<Text style={{color:'#328fa8', textAlign:'center', fontSize:15, fontWeight:'bold'}}>{formatDate(diaryDate)}</Text>)}</View>
                <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                    <Text style={{flexDirection:'row',fontSize:24,  color:'#b2b1b9',fontFamily:'Poppins' }}>Calories Remaining</Text>
                    <FontAwesome name='ellipsis-h' style={{fontSize:20 ,color:'#b2b1b9',flexDirection:'row' ,padding:10}}/>
                </View>
                <View >
                    {
                        (loading===true)?(<View style={{alignContent:'center', width:"100%"}}><ActivityIndicator/></View>):(
                            <View style={{flexDirection:'row', justifyContent:'space-between', margin:10}}>
                                <View style={styles.textSView}>
                                    <Text style={styles.textB}>{goalCalories}</Text>
                                </View>
                                <View style={styles.textSView}>
                                    <Text style={styles.textB}>-</Text>
                                </View>
                                <View style={styles.textSView}>
                                    <Text style={styles.textB}>{foodCalories}</Text>
                                </View>
                                <View style={styles.textSView}>
                                    <Text style={styles.textB}>=</Text>
                                </View>
                                <View style={styles.textSView}>
                                    <Text style={styles.textB}>{goalCalories-foodCalories}</Text>
                                </View>
                            </View>
                        )
                    }
                    
                </View>
                <View style={{flexDirection:'row', justifyContent:'space-around',  textAlign:'center', paddingBottom:10, width:'100%',}}>
                    <View style={styles.textSView}>
                        <Text style={styles.textS}>Goal</Text>
                    </View>
                    <View style={{ }}>
                        <Text style={styles.textS}> </Text>
                    </View>
                    <View style={styles.textSView}>
                        <Text style={styles.textS}>Food</Text>
                    </View>
                    <View style={{ }}>
                        <Text style={styles.textS}> </Text>
                    </View>
                    <View style={styles.textSView}>
                        <Text style={styles.textS}>Remaining</Text>
                    </View>
                </View>
            </View>
            

            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} 
            refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={onRefresh}
                />
              } >
                    <View style={{backgroundColor:'#183c57', padding:10,marginBottom:20, borderRadius:10}}>
                        <TouchableScale>
                            <View style={{flexDirection:'row', justifyContent:'space-between', margin:10}}>
                                <Text style={{color:'#5ec49f', fontSize:18}}>Breakfast</Text>
                                <Text style={{color:'#b2b1b9', fontSize:18}}>{(breakfastItems===null)?0:breakfastItems.length}</Text>
                            </View>
                        </TouchableScale>
                        <View style={{borderBottomColor: 'black',borderBottomWidth: StyleSheet.hairlineWidth,}}/>
                        <View style={{margin:10}}>
                            {
                                (loading!==false)?(<View style={{alignContent:'center', width:"100%"}}><ActivityIndicator/></View>):(
                                    (breakfastItems===null)?(<View><Text>No data found...</Text></View>):(
                                        breakfastItems.map((item, i)=>{
                                            // console.log("item: ", item)
                                            return (
                                                <View key={i} style={{flexDirection:'row', justifyContent:'space-between'}}>
                                                    <View style={{flex:2}}><Text style={{color:'#bdbdbd'}}>{item.foods.food_name} </Text></View>
                                                    <View style={{flex:1, justifyContent:'center'}}><Text style={{color:'#bdbdbd'}}>( {item.foods.serving_size.amount*item.serving_data.amount } g )</Text></View>
                                                    {/* <View style={{flex:1}}><Text style={{color:'#bdbdbd'}}> = </Text></View> */}
                                                    <View style={{flex:1, justifyContent:'center'}}><Text style={{color:'#bdbdbd'}}>= {getFoodServingsCalculateByID(item.serving_data, item.foods)} Cal</Text></View>
                                                </View>
                                            )
                                        }
                                    )
                                    
                                    )
                                )
                            }
                        </View>
                        {
                            (route.params?.DiaryDate && route.params?.DiaryDate!==currentDateCalender)?(<Text>Can't add new item</Text>):(
                                <View style={{flexDirection:'row', justifyContent:'space-between', margin:10}}>
                                    <TouchableScale onPress={()=>{navigation.navigate('AddFood', {MealType:"Breakfast"})}}>
                                        <Text style={{color:'#5ba5eb', fontSize:18}} >Add Food</Text>
                                    </TouchableScale>
                                    <TouchableScale>
                                        <FontAwesome name='ellipsis-h' style={{fontSize:20 ,color:'#b2b1b9',flexDirection:'row' ,}}/>
                                    </TouchableScale>
                                </View>

                            )
                        }
                    </View>

                    <View style={{backgroundColor:'#183c57', padding:10,marginBottom:20, borderRadius:10}}>
                        <TouchableScale>
                            <View style={{flexDirection:'row', justifyContent:'space-between', margin:10}}>
                                <Text style={{color:'#5ec49f', fontSize:18}}>Lunch</Text>
                                <Text style={{color:'#b2b1b9', fontSize:18}}>{(lunchItems===null)?0:lunchItems.length}</Text>
                            </View>
                        </TouchableScale>
                        <View style={{borderBottomColor: 'black',borderBottomWidth: StyleSheet.hairlineWidth,}}/>
                        <View style={{margin:10}}>
                            {
                                (loading!==false)?(<View style={{alignContent:'center', width:"100%"}}><ActivityIndicator/></View>):(
                                    (lunchItems===null)?(<View><Text>No data found...</Text></View>):(
                                        lunchItems.map((item, i)=>{
                                            // console.log("item: ", item)
                                            return (
                                                <View key={i} style={{flexDirection:'row', justifyContent:'space-between'}}>
                                                    <View ><Text style={{color:'#bdbdbd'}}>{item.foods.food_name} </Text></View>
                                                    <View ><Text style={{color:'#bdbdbd'}}>( {item.foods.serving_size.amount*item.serving_data.amount } g )</Text></View>
                                                    <View ><Text style={{color:'#bdbdbd'}}> = </Text></View>
                                                    <View ><Text style={{color:'#bdbdbd'}}>{getFoodServingsCalculateByID(item.serving_data, item.foods)} Cal</Text></View>
                                                </View>
                                            )
                                        })
                                    )
                                    
                                )
                            }
                        </View>
                        {
                        (route.params?.DiaryDate && route.params?.DiaryDate!==currentDateCalender)?(<Text>Can't add new item</Text>):(
                        <View style={{flexDirection:'row', justifyContent:'space-between', margin:10}}>
                            <TouchableScale onPress={()=>{navigation.navigate('AddFood', {MealType:"Lunch"})}}>
                                <Text style={{color:'#5ba5eb', fontSize:18}} >Add Food</Text>
                            </TouchableScale>
                            <TouchableScale>
                                <FontAwesome name='ellipsis-h' style={{fontSize:20 ,color:'#b2b1b9',flexDirection:'row' ,}}/>
                            </TouchableScale>
                        </View>
                        )
                        }
                    </View>

                    <View style={{backgroundColor:'#183c57', padding:10,marginBottom:20, borderRadius:10}}>
                        <TouchableScale>
                            <View style={{flexDirection:'row', justifyContent:'space-between', margin:10}}>
                                <Text style={{color:'#5ec49f', fontSize:18}}>Dinner</Text>
                                <Text style={{color:'#b2b1b9', fontSize:18}}>{(dinnerItems===null)?0:dinnerItems.length}</Text>
                            </View>
                        </TouchableScale>
                        <View style={{borderBottomColor: 'black',borderBottomWidth: StyleSheet.hairlineWidth,}}/>
                        <View style={{margin:10}}>
                            {
                                (loading!==false)?(<View style={{alignContent:'center', width:"100%"}}><ActivityIndicator/></View>):(
                                    (dinnerItems===null)?(<View><Text>No data found...</Text></View>):(
                                        dinnerItems.map((item, i)=>{
                                            // console.log("item: ", item)
                                            return (
                                                <View key={i} style={{flexDirection:'row', justifyContent:'space-between'}}>
                                                    <View ><Text style={{color:'#bdbdbd'}}>{item.foods.food_name} </Text></View>
                                                    <View ><Text style={{color:'#bdbdbd'}}>( { item.foods.serving_size.amount*item.serving_data.amount } g )</Text></View>
                                                    <View ><Text style={{color:'#bdbdbd'}}> = </Text></View>
                                                    <View ><Text style={{color:'#bdbdbd'}}>{getFoodServingsCalculateByID(item.serving_data, item.foods)} Cal</Text></View>
                                                </View>
                                            )
                                        })
                                    )
                                    
                                )
                            }
                        </View>
                        {
                            (route.params?.DiaryDate && route.params?.DiaryDate!==currentDateCalender)?(<Text>Can't add new item</Text>):(
                                <View style={{flexDirection:'row', justifyContent:'space-between', margin:10}}>
                                    <TouchableScale onPress={()=>{navigation.navigate('AddFood', {MealType:"Dinner"})}}>
                                        <Text style={{color:'#5ba5eb', fontSize:18}} >Add Food</Text>
                                    </TouchableScale>
                                    <TouchableScale>
                                        <FontAwesome name='ellipsis-h' style={{fontSize:20 ,color:'#b2b1b9',flexDirection:'row' ,}}/>
                                    </TouchableScale>
                                </View>
                            )
                        }
                    </View>

                    <View style={{backgroundColor:'#183c57', padding:10,marginBottom:20, borderRadius:10}}>
                        <TouchableScale>
                            <View style={{flexDirection:'row', justifyContent:'space-between', margin:10}}>
                                <Text style={{color:'#5ec49f', fontSize:18}}>Snacks</Text>
                                <Text style={{color:'#b2b1b9', fontSize:18}}>0</Text>
                            </View>
                        </TouchableScale>
                        <View style={{borderBottomColor: 'black',borderBottomWidth: StyleSheet.hairlineWidth,}}/>
                        {
                            (route.params?.DiaryDate && route.params?.DiaryDate!==currentDateCalender)?(<Text>Can't add new item</Text>):(
                            <View style={{flexDirection:'row', justifyContent:'space-between', margin:10}}>
                                <TouchableScale onPress={()=>{navigation.navigate('AddFood', {MealType:"Snacks"})}}>
                                    <Text style={{color:'#5ba5eb', fontSize:18}} >Add Food</Text>
                                </TouchableScale>
                                <TouchableScale>
                                    <FontAwesome name='ellipsis-h' style={{fontSize:20 ,color:'#b2b1b9',flexDirection:'row' ,}}/>
                                </TouchableScale>
                            </View>
                            )
                        }
                    </View>

                    <View style={{backgroundColor:'#183c57', padding:10,marginBottom:20, borderRadius:10}}>
                        <TouchableScale>
                            <View style={{flexDirection:'row', justifyContent:'space-between', margin:10}}>
                                <Text style={{color:'#5ec49f', fontSize:18}}>Exercise</Text>
                                <Text style={{color:'#b2b1b9', fontSize:18}}>0</Text>
                            </View>
                        </TouchableScale>
                        <View style={{borderBottomColor: 'black',borderBottomWidth: StyleSheet.hairlineWidth,}}/>
                        <View style={{flexDirection:'row', justifyContent:'space-between', margin:10}}>
                            <TouchableScale onPress={()=>{navigation.navigate('Home')}}>
                                <Text style={{color:'#5ba5eb', fontSize:18}} >Add Exercise</Text>
                            </TouchableScale>
                            <TouchableScale>
                                <FontAwesome name='ellipsis-h' style={{fontSize:20 ,color:'#b2b1b9',flexDirection:'row' ,}}/>
                            </TouchableScale>
                        </View>
                    </View>

                    <View style={{backgroundColor:'#183c57', padding:10,marginBottom:20, borderRadius:10}}>
                        <TouchableScale>
                            <View style={{flexDirection:'row', justifyContent:'space-between', margin:10}}>
                                <Text style={{color:'#5ec49f', fontSize:18}}>Water</Text>
                                <Text style={{color:'#b2b1b9', fontSize:18}}>0</Text>
                            </View>
                        </TouchableScale>
                        <View style={{borderBottomColor: 'black',borderBottomWidth: StyleSheet.hairlineWidth,}}/>
                        <View style={{flexDirection:'row', justifyContent:'space-between', margin:10}}>
                            <TouchableScale onPress={()=>{navigation.navigate('Home')}}>
                                <Text style={{color:'#5ba5eb', fontSize:18}} >Add Water</Text>
                            </TouchableScale>
                            <TouchableScale>
                                <FontAwesome name='ellipsis-h' style={{fontSize:20 ,color:'#b2b1b9',flexDirection:'row' ,}}/>
                            </TouchableScale>
                        </View>
                    </View>
                    <View style={{flexDirection:'row', justifyContent:'space-between', margin:10}}>
                <TouchableScale>
                    <View style={{backgroundColor:'#183c57', borderRadius:20, padding:10, margin:10, paddingHorizontal:20}}>
                            <Text style={{color:'#5ec49f', fontSize:18}}>
                                <FontAwesome name='bar-chart' style={{fontSize:20 ,color:'gray',flexDirection:'row' ,}}/> 
                            Nutrition</Text>
                        </View>
                </TouchableScale>
                <TouchableScale>
                    <View style={{backgroundColor:'#183c57',borderRadius:20, padding:10, margin:10,paddingHorizontal:20}}>
                        <Text style={{color:'#5ec49f', fontSize:18}}>
                        <FontAwesome name='sticky-note' style={{fontSize:20 ,color:'gray',flexDirection:'row' ,}}/> 
                            Notes</Text>
                    </View>  
                </TouchableScale>
                </View>

            <TouchableScale>
                <View style={{backgroundColor:'#183c57',paddingHorizontal:'15%', paddingVertical:10, borderRadius:20}}>
                    <Text style={{color:'#5ec49f', fontSize:18, textAlign:'center'}}>Complete Diary</Text>
                </View> 
            </TouchableScale>
               </ScrollView>
                

           

            
        </View>
    )
}

const styles = StyleSheet.create({
    container:{
      flex: 1,
      backgroundColor: '#000',
      padding:15
  },
  textB:{
    color:'#b2b1b9', 
    fontSize:18,
    fontFamily:'Poppins',
    textAlign:'center',
  },
  textS:{
    color:'#b2b1b9', 
    fontSize:12,
    textAlign:'center',
    fontFamily:'Poppins',
    // paddingHorizontal:'5%',
    // borderColor:'black',
    // borderWidth:1,
  },
  textSView:{
    width:"20%",
    // flex:1,
  }
})