import { StatusBar } from 'expo-status-bar';
import { Button, StyleSheet, Text, View,SafeAreaView,Dimensions, Image , ScrollView, ActivityIndicator} from 'react-native';
import CustomButton from '../components/CustomButton';
const screenWidth = Math.round(Dimensions.get('window').width);
const screenHeight = Math.round(Dimensions.get('window').height);
import * as Progress from 'react-native-progress';
import { useFonts } from 'expo-font';
import {FontAwesome} from '@expo/vector-icons';
import { SupabaseConfig } from '../hooks/SupabaseConfig';
import { useEffect, useState } from 'react';




export default function Profile({navigation}) {

  let [fontsLoad] = useFonts({
    Poppins : require('../../assets/fonts/Poppins.ttf'),
    Pattaya : require('../../assets/fonts/Pattaya.ttf')
});

 const [loading, setLoading] = useState(true);
 const [profileData, setProfileData] = useState(null);




 const loadData = async()=>{
  setLoading(true);
  const { data: { session } } = await SupabaseConfig.auth.getSession();
  if (session) {
    setProfileData(session.user);
  }

  setLoading(false);

 }

 

 useEffect(()=>{
  navigation.addListener('focus', ()=>{
      loadData()
  })
 }, [])

 if(!fontsLoad){
  return null;
}

  return (
        <ScrollView style={styles.container}>
          <View style={styles.headerProfile}>

          <Image source={require('../../assets/cris-profile.webp')} 
          style={styles.imageProfile} resizeMode={"cover"}/>
          <View style={{flexDirection: 'row'}}>
            {loading===true?(<ActivityIndicator />):(<Text style={styles.text}>{profileData.user_metadata.nick_name}</Text>)}
            {/* <FontAwesome name='fire' style={{fontSize:16 ,color:'orange',flexDirection:'row', padding:10}}/> */}

          </View>
          </View>
          {/* <View style={{marginTop:-35, marginBottom:30}}>
            <Text style={{textAlign:'center', color:'#99acad', fontSize:20, fontWeight:'bold',fontFamily:'Poppins'}}>Expert</Text>
            <Text style={{textAlign:'center', color:'#ddd', fontSize:20, fontWeight:'bold',fontFamily:'Poppins'}}>Adonis || Level 100</Text>
            <Progress.Bar progress={0.6} width={null} color={'#a7ebb9'} borderColor={'#000'} unfilledColor={'#000'} borderRadius={25} height={11} style={{ marginHorizontal:20, marginVertical:10}} />
            <Text style={{textAlign:'center', color:'#989c99', fontSize:15, fontWeight:'bold',fontFamily:'Poppins'}}>Need 25 points to level up</Text>
            <Text style={{textAlign:'center', color:'#989c99', fontSize:15, fontWeight:'bold',fontFamily:'Poppins'}}>Streak : 5 Days</Text>
          </View> */}
          
          <View style={{flexDirection:'row', justifyContent:'space-around',marginHorizontal:10, textAlign:'center', paddingBottom:10}}>
                    <Text style={styles.textB}>22</Text>
                    <Text style={styles.textB}>30</Text>
                    <Text style={styles.textB}>165</Text>
                    <Text style={styles.textB}>158</Text>
          </View>
          <View style={{flexDirection:'row', justifyContent:'space-around',marginHorizontal:10, textAlign:'center', paddingBottom:10}}>
                    <Text style={styles.textS}>Age</Text>
                    <Text style={styles.textS}>BMI</Text>
                    <Text style={styles.textS}>Height</Text>
                    <Text style={styles.textS}>Weight</Text>
          </View>


        <View>
          <CustomButton Icon="dumbbell" Label="My Workouts" Click={() => navigation.navigate('MyWorkout')} />
          <CustomButton Icon="library" Label="My Diary" Click={() => navigation.navigate('MyDiary')} />
          <CustomButton Icon="newspaper" Label="My Posts" Click={() => navigation.navigate('MyPosts')} />
          <CustomButton Icon="silverware-fork-knife" Label="My Diets" Click={() => navigation.navigate('Home')} />
          <CustomButton Icon="heart-outline" Label="Favourites" Click={() => navigation.navigate('Home')} />
          <CustomButton Icon="bookmark-outline" Label="About US" Click={() => navigation.navigate('Home')} />
          <CustomButton Icon="file-document-outline" Label="Privacy & Terms" Click={() => navigation.navigate('Home')} />
          <CustomButton Icon="logout" Label="Sign Out" Click={() => navigation.navigate('Home')} />
        </View>
          
      </ScrollView>
   
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000',
   
    // marginBottom: 40
  },
  text:{
    color:'#ddd',
    alignContent:'center',
    justifyContent:'center',
    fontSize:20
  },
  headerProfile:{
    width: '100%',
    height: screenHeight*0.30,
    alignItems: 'center',
    justifyContent: 'center',
    },
  imageProfile:{
    borderRadius: 100,
    width: screenWidth*0.28,
    height: 'auto',
    minHeight: screenWidth*0.28,
      // marginBottom: 20	
      },
    textB:{
      color:'#ddd', 
      fontSize:25,
      fontFamily:'Poppins'
    },
    textS:{
      color:'#ddd', 
      fontSize:15,
      textAlign:'center',
      paddingLeft:5,
      fontFamily:'Poppins'
    }
});