import React from 'react'
import { View } from 'react-native'
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import Dashboard from '../Dashboard'; 
import Newsfeed from '../Newsfeed';
import Profile from '../Profile';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Feather from 'react-native-vector-icons/Feather';
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import Home from '../Home';
import AppStackNavigation from './AppStackNavigation';
import Diary from '../Diary';
import Exercise from '../Exercise';
import Workout from '../Workout';
import { screenHeight } from '../../Utils/Dimensions';


const Tab = createBottomTabNavigator();

function BottomTabNavigation({navigation}) {
  return (
    <Tab.Navigator screenOptions={{
        headerShown: false,
        // tabBarShowLabel: false,
        tabBarStyle: {backgroundColor: '#0b141f', height: screenHeight*0.10, borderTopWidth:0},
        tabBarInactiveTintColor: '#586566',
        tabBarActiveTintColor: '#fff',
        tabBarLabelStyle:{fontSize:15, paddingBottom:10},
    }}>
        <Tab.Screen name='Dashboard' component={AppStackNavigation} options={{
            tabBarIcon: ({color, size}) => (
                <MaterialCommunityIcons name="view-dashboard-outline" color={color} size={size} />
            )
        }} />
        <Tab.Screen name='Diary' component={Diary} options={{
            tabBarIcon: ({color, size}) => (
                <MaterialCommunityIcons name="library" color={color} size={size} />
            )
        }} />
        <Tab.Screen name='Newsfeed' component={Newsfeed} options={{
            tabBarIcon: ({color, size}) => (
                <Ionicons name="newspaper-outline" color={color} size={size} />
            )
        }} />
        <Tab.Screen name='Exercise' component={Workout} options={{
            tabBarIcon: ({color, size}) => (
                <MaterialCommunityIcons name="dumbbell" color={color} size={size} />
            )
        }} />
        {/* <Tab.Screen name='Menu' component={Home} options={{
            tabBarIcon: ({color, size}) => (
                <Ionicons name="menu-outline" color={color} size={size} />
            )
        }} 
        // listeners={({ navigation }) => ({
        //     tabPress: e => {
        //       e.preventDefault();
        //       navigation.openDrawer();
        //     }
        //   })} 
          /> */}
    </Tab.Navigator>
  )
}

export default BottomTabNavigation