import { StatusBar } from 'expo-status-bar';
import { Button, StyleSheet, Text, View, TextInput,Dimensions } from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
import TouchableScale from 'react-native-touchable-scale';
import * as Progress from 'react-native-progress';



const screenWidth = Math.round(Dimensions.get('window').width);
const screenHeight = Math.round(Dimensions.get('window').height);


export default function Screen1({navigation}) {
    return (
      <View style={styles.container}>
        <View>
          <View>
            <Progress.Bar progress={0.11} width={null} color={'#a7ebb9'} borderColor={'#244761'} unfilledColor={'#000'} borderRadius={0} height={7} style={{ marginVertical:10}} />
          </View>

          <View style={{ marginVertical: 20}}>
            <Text style={{ color:'#ddd', fontSize:16, fontWeight:'bold'}}>Let's Start With Goals</Text>
            <Text style={{ color:'#ddd', fontSize:13,}}>Select upto 3 that are important to you, including one weight goal.</Text>

          </View>

          <View>
            <TouchableScale style={styles.templateCard}>
              <View style={{ padding:5}}>
                  <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold"}}>Lose Weight</Text>
              </View>
            </TouchableScale>
            <TouchableScale style={styles.templateCard} >
              <View style={{ padding:5}}>
                  <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold"}}>Maintain Weight</Text>
              </View>
            </TouchableScale>
            <TouchableScale style={styles.templateCard} >
              <View style={{ padding:5}}>
                  <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold"}}>Gain Weight</Text>
              </View>
            </TouchableScale>
            <TouchableScale style={styles.templateCard} >
              <View style={{ padding:5}}>
                  <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold"}}>Gain Muscle</Text>
              </View>
            </TouchableScale>
            <TouchableScale style={styles.templateCard} >
              <View style={{ padding:5}}>
                  <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold"}}>Modify My Diet</Text>
              </View>
            </TouchableScale>
            <TouchableScale style={styles.templateCard} >
              <View style={{ padding:5}}>
                  <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold"}}>Manage Stress</Text>
              </View>
            </TouchableScale>
            <TouchableScale style={styles.templateCard} >
              <View style={{ padding:5}}>
                  <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold"}}>Increase My Step Count</Text>
              </View>
            </TouchableScale>
          </View>
        </View>


        <TouchableScale style={{padding:4,backgroundColor:'#148be3',marginVertical:5,borderRadius:25, }} onPress={()=>{navigation.navigate('Screen2')}}>
            <View style={{ padding:5}}>
                <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold", textAlign:'center'}}>Next</Text>
            </View>
          </TouchableScale>
      </View>
      
    )
}


const styles = StyleSheet.create({
    container:{
      flex: 1,
      backgroundColor: '#000',
      padding:15,
  },
  templateCard:{
    padding:4,
    backgroundColor:'#102a43',
    marginVertical:5
},
})