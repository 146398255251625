import { StatusBar } from 'expo-status-bar';
import { Button, StyleSheet, Text, View, TextInput,Dimensions } from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
import TouchableScale from 'react-native-touchable-scale';
import * as Progress from 'react-native-progress';


const screenWidth = Math.round(Dimensions.get('window').width);
const screenHeight = Math.round(Dimensions.get('window').height);



export default function Screen9({navigation}) {
    return (
      <View style={styles.container}>
        <View>
          <View>
            <Progress.Bar progress={1} width={null} color={'#a7ebb9'} borderColor={'#244761'} unfilledColor={'#000'} borderRadius={0} height={7} style={{ marginVertical:10}} />
          </View>

          

          <View>
          <View style={{paddingTop:30}}>
        <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold",  borderColor:'#fff', paddingBottom:10}}>How tall are you?</Text>
         <TextInput style={{padding:4,backgroundColor:'#102a43',marginVertical:5}} placeholder='Height' placeholderTextColor='#ddd' textAlign='center' color='#fff'/>
        </View>
        <View style={{paddingTop:30}}>
        <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold",  borderColor:'#ddd', paddingBottom:10}}>How much do you weigh?</Text>
         <TextInput style={{padding:4,backgroundColor:'#102a43',marginVertical:5}} placeholder='Weight' placeholderTextColor='#ddd' textAlign='center' color='#fff'/>
        </View>
        <View style={{paddingTop:30}}>
        <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold",  borderColor:'#ddd', paddingBottom:10}}>What is your goal weight?</Text>
         <TextInput style={{padding:4,backgroundColor:'#102a43',marginVertical:5}} placeholder='Weight' placeholderTextColor='#ddd' textAlign='center' color='#fff'/>
        </View>
        <View style={{paddingTop:30}}>
        <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold",  borderColor:'#ddd', paddingBottom:10}}>What is your weekly goal?</Text>
        <TouchableScale style={styles.templateCard}>
              <View style={{ padding:5}}>
                  <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold"}}>Gain 0.25 kg per week</Text>
              </View>
            </TouchableScale>
            <TouchableScale style={styles.templateCard}>
              <View style={{ padding:5}}>
                  <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold"}}>Gain 0.5 kg per week</Text>
              </View>
            </TouchableScale>
        </View>
          
          


          </View>
        </View>
        <TouchableScale style={{padding:4,backgroundColor:'#148be3',marginVertical:5,borderRadius:25, }} onPress={()=>{navigation.navigate('Register')}}>
            <View style={{ padding:5}}>
                <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold", textAlign:'center'}}>Next</Text>
            </View>
          </TouchableScale>
      </View>
    )
}


const styles = StyleSheet.create({
    container:{
      flex: 1,
      backgroundColor: '#000',
      padding:15
  },
  templateCard:{
    padding:4,
    backgroundColor:'#102a43',
    marginVertical:5
},
})