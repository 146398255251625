import react,{useEffect, useState} from 'react';
import { StatusBar } from 'expo-status-bar';
import { Button, StyleSheet, Text, View, TextInput, FlatList , TouchableOpacity, ScrollView, ActivityIndicator, Alert} from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
// import TouchableScale from 'react-native-touchable-scale';
import { useRoute } from '@react-navigation/native';
import {currentDate, currentDateCalender} from '../Utils/DateTime'
import { storeExerciseFullData, getExerciseFullData, removeExerciseFullData } from '../hooks/ExerciseHook';
import { getDiaryFullData, getFoodFullData, removeDiaryFullData, storeDiaryFullData, updateDiaryData } from '../hooks/DiaryHook';
import TouchableScale from 'react-native-touchable-scale';

const numberFix = (value)=>{
    if (typeof value === 'number' && !Number.isNaN(value) && !Number.isInteger(value) ) {
        return value.toFixed(2);
    }
    return value;
}

export default function AddFood2({navigation}) {
    const [loading, setLoading] = useState(true)
    const route = useRoute();
    const [selectedFood, setSelectedFood] = useState(null)
    const [mealType, setMealType] = useState(null)
    const [mealData, setMealData] = useState(null);

    const [disabledButton, setDisabledButton] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    
    
    const [servingAmount, setServingAmount] = useState(1);
    
    // console.log("route.params.SelectedFood: ",route.params.SelectedFood);
    const loadData = () =>{
        // getFoodFullData().then((foods)=>{
            setSelectedFood(route.params.SelectedFood);
            const selectedFoodTemp = route.params.SelectedFood;
            setMealType(route.params.MealType);
            // if (foods) {
                getDiaryFullData(currentDateCalender).then((diaries)=>{

                    console.log("route.params.SelectedFood || selectedFoodTemp: ", selectedFoodTemp);
                    // let initialFoodSets;
                    const initialFoodSets = {
                            foodID:selectedFoodTemp.id,
                            servingSize:1,
                            isExists:false
                        }

                    setMealData({mealType:route.params.MealType, food:initialFoodSets});

                    

                    
                    console.log("initialFoodSets: ", initialFoodSets)
                    // console.log("diaryDetailsTemp: ", diaryDetailsTemp)

                    setLoading(false)
                })
            // }
        // })
    }

    

    const handleServingSize = (onChangeServing, foodID) =>{
        
        console.log(mealType,"  mealData: ", mealData);
        // console.log("diaryData: ", diaryData);
        const food = mealData.food;
        const updatedData = {...food, servingSize: ((onChangeServing.length>0)?numberFix(onChangeServing):0)}
        setServingAmount((onChangeServing.length>0)?numberFix(onChangeServing):1)
        // const updatedDiaryDetails = diaryData.diaryDetails.map((item, i)=>{
        //     if (item.mealType===mealType) {
        //         item.foods = updatedData;
        //         // console.log("item: ",item)
        //     }
        //     return item;
        // })
        // console.log("updatedDiaryDetails: ", updatedDiaryDetails)
        const tempMealData = {food:updatedData, mealType:mealType };
        // const tempDiaryData = {...diaryData, diaryDetails:updatedDiaryDetails};
        // console.log("tempDiaryData: ", tempDiaryData)
        setMealData(tempMealData);
        // setDiaryData(tempDiaryData)
        setDisabledButton(false);
    }


    const getServingValue = (foodID) =>{
        // const food = mealData.food.find((item)=>item.foodID===foodID)
        return numberFix(mealData.food.servingSize);
    }
    
/**
data structure

{ date:"", meal_type:"",food_id:"",serving_data: {amount:200} }


FoodDiary
--------------
[
    {
        date:"10/1/2023",
        diaryDetails: [
            {
                type:"breakfast",
                foods: [
                    {
                        foodID:"1",
                        servingSize:""
                    },
                    {
                        foodId:"2",
                        servingSize:""
                    },

                ]
            },
            {
                type:"lunch",
                foods: [
                    {
                        foodID:"",
                        servingSize:""
                    },
                    {
                        foodId:"",
                        servingSize:""
                    },

                ]
            },
        ]
    },
    {

    }
]

 
 */

const handleSaveField = (food_id) =>{
    setBtnLoading(true);
    if (food_id) {
        console.log("mealData from save: ", mealData);
        const food = mealData.food;
        if(food.servingSize<1){
            alert("Servings can't be less than 1")
            setBtnLoading(false);
        }else{
            if (food.isExists) {
                const formatedData = {serving_data: {"amount":food.servingSize, "unit":selectedFood.serving_size.unit}};
                updateDiaryData(food.rowId, formatedData).then((result)=>{
                    console.log("update result: ", result);
                    if (result) {
                        setDisabledButton(true);
                        // alert("Successfully updated the item!");
                    }
                    setBtnLoading(false);
                })
                
            }else{
                const formatedData = {date:currentDateCalender, meal_type:mealType, food_id:food.foodID, serving_data: {"amount":food.servingSize, "unit":selectedFood.serving_size.unit} }
                console.log("formatedData: ", formatedData);
                storeDiaryFullData(formatedData).then((result)=>{
                    console.log("store result: ", result);
                    if(result){
                        setDisabledButton(true);
                        // alert("Successfully added the item!");
                    }
                    setBtnLoading(false);
                })
            }
        }
        
    }

}
   
/*
    const storeData=()=>{
        // removeDiaryFullData();
        console.log("getStoredFoodFullData: ",getStoredFoodFullData);
        const newDiaryDetails =  diaryData.diaryDetails.map((diaryD, index)=>{
            diaryD.foods = diaryD.foods.filter(food=>{
                return food.servingSize>0 && food.servingSize!==NaN;
            })
            // console.log("diaryD: ",diaryD.foods)
            return diaryD;
        })
        console.log("newDiaryDetails: ", newDiaryDetails);


        if (getStoredFoodFullData!==null) {
            const findData = getStoredFoodFullData.find(item => item.date===currentDate);
            let updatedData=null;
            if (findData) {
                updatedData = getStoredFoodFullData.map((item)=>{
                    if (item.date === currentDate) {
                        item.diaryDetails=diaryData.diaryDetails;
                    }
                    return item;
                })
            }
            else{
                updatedData = [...getStoredFoodFullData, diaryData];
            }
            console.log(updatedData[0].diaryDetails[0]);
            console.log(updatedData[0].diaryDetails[1]);
            storeDiaryFullData(updatedData).then(()=>{
                Alert.alert("Success","Successfully added!")
                navigation.navigate("Diary",{refresh:true});
            });
        }else{
            let mergeAfterFilter = diaryData;
            mergeAfterFilter.diaryDetails = newDiaryDetails;
            console.log("mergeAfterFilter: ",mergeAfterFilter);

            storeDiaryFullData([mergeAfterFilter]).then(()=>{
                Alert.alert("Success","Successfully added!")
                navigation.navigate("Diary",{refresh:true});
            });
        }
        
    }

    */

    useEffect(()=>{
        loadData();
    },[])
// console.log("mealData: ", mealData)
// console.log("Selected: ", selectedFood);

  return (
    <View style={styles.container}>
        <View style={{width:"100%", paddingBottom:5, flexDirection:'row', justifyContent:'space-between', borderColor:'#ddd', borderBottomWidth:1}}>
        <View style={{}}><Text style={{color:"#fff", fontSize:17}}>Today: {currentDate}</Text></View>
        <View>
        {/* <View style={{justifyContent:'center',}}> */}
            {/* <TouchableScale style={{marginHorizontal:8}} onPress={()=>{handleSaveField(selectedFood.id)}} disabled={disabledButton}>
                <FontAwesome name='check-circle' style={{fontSize:25 ,color: disabledButton?'#55693c':'#4fe825', }}/>
            </TouchableScale> */}

        {/* </View> */}
            {/* <TouchableOpacity onPress={()=>{alert("storeData()")}}>
                <Text style={{backgroundColor:'green', padding:3}}>Save Data</Text>
            </TouchableOpacity> */}
        </View>
        </View>
        
        <ScrollView>
            {
               (loading!==false)?(<View><ActivityIndicator/></View>):(
                    <View>
                        <View style={{paddingHorizontal:7}}>
                            <View>
                                <Text style={{color:'#bdbdbd', fontSize:16}}>{selectedFood.food_name}</Text>
                            </View>
                            <View>
                                <Text style={{fontSize:15,fontWeight:'bold',marginVertical:5,color:'#bdbdbd'}}>Nutritions: {numberFix(selectedFood.food_nutrients.calories*servingAmount)} Cal</Text>
                            </View>
                            <View style={{flexDirection:'row', justifyContent:'space-between', marginTop:10}}>
                                <View>
                                    <Text style={{color:'#bdbdbd',fontSize:15,fontWeight:'bold',}}>Serving Size: </Text>
                                </View>
                                <View style={{ width:'20%'}}>
                                    <Text style={{color:'#bdbdbd',fontSize:15}}>{selectedFood.serving_size.amount} {selectedFood.serving_size.unit}</Text>
                                </View>
                            </View>
                            <View style={{flexDirection:'row', justifyContent:'space-between', paddingVertical:5}}>
                                <View style={{justifyContent:'center',}}>
                                    <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:'bold'}}>Number of Serving:</Text>
                                </View>
                                <View style={{justifyContent:'center', width:'20%'}}>
                                    <TextInput disabled={disabledButton} placeholder='Servings' style={{backgroundColor:'#636466', width:'100%', height:33, color:'#ddd',borderRadius:5, textAlign:'center', }} onChangeText={text => handleServingSize(text, selectedFood.id)} value={getServingValue(selectedFood.id)}></TextInput>
                                </View>
                                {/* <View style={{flex:1,}}><Text style={{color:'#bdbdbd', fontSize:15, fontWeight:'bold'}}>{selectedFood.serving_size.unit}</Text></View> */}



                                {/* <TouchableScale style={{justifyContent:'center'}} onPress={()=>{alert('Delete feature loading...')}}>
                                    <FontAwesome name='times-circle' style={{fontSize:20 ,color:'#d61106', }}/>
                                </TouchableScale> */}
                                
                            </View>

                            <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                                <View style={{}}>
                                    <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:'bold'}}>Meal Type:</Text>
                                </View>
                                <View style={{justifyContent:'center', width:'25%'}}>
                                    <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:'bold'}}>{mealType}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={{margin:10, alignItems:'center' }}>
                            {
                            btnLoading===true?(<ActivityIndicator/>):(
                            <TouchableScale style={{marginHorizontal:8, width:'50%', }} onPress={()=>{handleSaveField(selectedFood.id)}} disabled={disabledButton}>
                                <Text style={{fontSize:25 , backgroundColor:disabledButton?'#55693c':'#4fe825', textAlign:'center' }}>Save</Text>
                            </TouchableScale>
                            )
                            }
                        </View>
                    </View>
                )
            }
        </ScrollView>
        {/* <Button title='Home' onPress={()=>{navigation.navigate("Diary")}}/> */}

    </View>
  )
}





const styles = StyleSheet.create({
  container:{
    flex: 1,
    backgroundColor: '#000',
    padding:15
},
input:{
  backgroundColor:'#ddd',
  borderColor:'#000',
  marginBottom:10,
  // marginLeft:30,
  // marginRight:30,
  marginTop:30,
  alignContent:'center',
  justifyContent:'center',
  borderRadius:5,
  paddingLeft:15,
  paddingRight:15,
},
test:{
    color:'#ddd',
    paddingLeft:20,
    paddingTop:20,
    paddingBottom:10,
    fontSize:15,
    marginLeft:15
},
test2:{
    color:'#ddd',
    // padding:20,
    fontSize:15,
    marginLeft:25,
},
input2:{
    backgroundColor:'#ddd',
    borderColor:'#000',
    marginBottom:10,
    // marginLeft:30,
    // marginRight:30,
    // marginTop:30,
    alignContent:'center',
    justifyContent:'center',
    marginLeft:25,
    // marginEnd:20,
    borderRadius:5,
    // paddingLeft:15,
    // paddingRight:15,
  },

})