import react,{useEffect, useState} from 'react';
import { StatusBar } from 'expo-status-bar';
import { Button, StyleSheet, Text, View, TextInput, FlatList , TouchableOpacity, ScrollView, ActivityIndicator, Alert} from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
// import TouchableScale from 'react-native-touchable-scale';
import { useRoute } from '@react-navigation/native';
import {currentDate} from '../Utils/DateTime'
import { storeExerciseFullData, getExerciseFullData, removeExerciseFullData, getListedWorkout } from '../hooks/ExerciseHook';

/**

Enegry

-------
[
    {
        date:"10/1/2023",
        foodDetails: [
            {
                type:"breakfast",
                food: [
                    {
                        foodId:"",
                        foodname:"chicken",
                        energyCount:"",
                        servingSize:""
                    },
                    {
                        foodId:"",
                        foodname:"paratha",
                        energyCount:"",
                        servingSize:""
                    },

                ]
            },
            {
                type:"lunch",
                food: [
                    {
                        foodId:"",
                        foodname:"chicken curry",
                        energyCount:"",
                        servingSize:""
                    },
                    {
                        foodId:"",
                        foodname:"rice",
                        energyCount:"",
                        servingSize:""
                    },

                ]
            },
        ]
    },
    {
        
    }
]



 */



function Sets({id, exerciseID, data, handleReps, handleWeight}){
    // console.log(id)
    return (
        <View>
            <View style={{flexDirection:'row', justifyContent:'space-around', marginBottom:5}}>
                <View style={{flex:1}}>
                    <Text style={{textAlign:'center', color:'#ddd', paddingTop:5,paddingBottom:5,fontSize:15}}>{id+1}</Text>

                </View>
                <View style={{flex:1}}>
                    <Text style={{textAlign:'center', color:'#ddd', paddingBottom:5,fontSize:15 }}>--</Text>
                </View>
                <View style={{flex:1, alignItems:'center'}}>
                    <TextInput value={data.weight} placeholder='Weight' style={{backgroundColor:'#636466', width:56, height:30, color:'#ddd',borderRadius:5, paddingLeft:5,paddingRight:5, textAlign:'center'}} onChangeText={text => handleWeight(text, exerciseID, id)}></TextInput>
                </View>
                <View style={{flex:1, alignItems:'center'}}>
                    <TextInput value={data.reps} placeholder='REPS' style={{backgroundColor:'#636466', width:56, height:30, color:'#ddd',borderRadius:5, paddingLeft:5,paddingRight:5, textAlign:'center'}}  onChangeText={text => handleReps(text, exerciseID, id)}></TextInput>
                </View>
                            
            </View>
        </View>
    )
}


export default function Template2({navigation}) {
    const route = useRoute();
    const [loading, setLoading] = useState(true);
    const [getStoredExerciseFullData, setGetStoredExerciseFullData] = useState(null);
    const [selectedExercise, setSelectedExercise] = useState(null);
    const [workoutList, setWorkoutList] = useState(null)
    const [exerciseDate, setExerciseDate] = useState(null);
    
    
    
    // console.log(getStoredExerciseFullData===null);
    
    const [exerciseData, setExerciseData] = useState(null)
    const loadData = () =>{
        getListedWorkout().then((datas)=>{
            if (datas){
                // setWorkoutList(datas);
                // console.log("listed Workout: ", datas);

                getExerciseFullData().then((data)=>{
                    let initialSets=null;
                    
                    setGetStoredExerciseFullData(data);
                    // console.log(data)
                    if (route.params.SelectedWorkout) {
                        setSelectedExercise(route.params.SelectedWorkout);
                        setExerciseDate(currentDate);
                        initialSets = route.params.SelectedWorkout.map((item, i)=>{
                            return {
                                exerciseID:item.id,
                                sets:[]
                            }
                        })
                    }
                    if (route.params.ExerciseDate) {
                        setExerciseDate(route.params.ExerciseDate);
                        const findData = data.find(item => item.date===route.params.ExerciseDate);
                        initialSets = findData.exerciseDetails;
                        // console.log("initialSets: ",initialSets);
                        const selectedExTemp = findData.exerciseDetails.map((item)=>{
                            // console.log("workoutList: ",datas)
                            return datas.find(item2=>item2.id===item.exerciseID);
                            // console.log("item: ", item.exerciseID);
                        });
                        // console.log("selectedExTemp: ",selectedExTemp)
                        setSelectedExercise(selectedExTemp)
                        // updatedData = data.map((item)=>{
                        //     if (item.date === currentDate) {
                        //         item.exerciseDetails=newExDetails;
                        //         // item.exerciseDetails=exerciseData.exerciseDetails;
                        //     }
                        //     return item;
                        // })
                        // initialSets = route.params.SelectedWorkout.map((item, i)=>{
                        //     return {
                        //         exerciseID:item.id,
                        //         sets:[]
                        //     }
                        // })
                    }
                    setExerciseData({
                        date:(route.params.ExerciseDate)?route.params.ExerciseDate:currentDate,
                        exerciseDetails: initialSets,
                    })
                    setLoading(false);
                });



            }
          })
        
        
        
        // console.log("initialSets: ",initialSets);
        
        // console.log("exerciseDate : ",exerciseDate);
        
        
        

    }

    const addRows = (exerciseID) =>{
        // console.log("exerciseData: ",exerciseData);
        const exercise = exerciseData.exerciseDetails.find(item=> item.exerciseID===exerciseID)
        const sets = exercise.sets;
        const updatedData = exerciseData.exerciseDetails.map((exData, i)=>{
            if (exData.exerciseID===exerciseID) {
                exData.sets = [...sets, {weight:null, reps:null}]
            }
            return exData;
        })
        // console.log("updatedData: ", updatedData[0].sets)
        setExerciseData({...exerciseData, exerciseDetails:updatedData})
       
    }

    const handleWeight = (onChangeWeight, exerciseID, i) =>{
        const exercise = exerciseData.exerciseDetails.find(item=> item.exerciseID===exerciseID)
        const sets = exercise.sets;
        const updatedData = exerciseData.exerciseDetails.map((exData, index)=>{
            if (exData.exerciseID===exerciseID) {
                const savedData = [...sets]
                savedData[i].weight=onChangeWeight
                exData.sets = savedData
            }
            return exData;
        })
        setExerciseData({...exerciseData, exerciseDetails:updatedData})
    }
    const handleReps = (onChangeReps, exerciseID, i) =>{
        // const savedData = [...sets]
        // savedData[i].reps=onChangeReps
        // setSets(savedData);
        const exercise = exerciseData.exerciseDetails.find(item=> item.exerciseID===exerciseID)
        const sets = exercise.sets;
        const updatedData = exerciseData.exerciseDetails.map((exData, index)=>{
            if (exData.exerciseID===exerciseID) {
                const savedData = [...sets]
                savedData[i].reps=onChangeReps
                exData.sets = savedData
            }
            return exData;
        })
        // console.log("updatedData: ", updatedData)
        setExerciseData({...exerciseData, exerciseDetails:updatedData})
    }
/**
data structure

[
    {
        date: 30-1-12,
        exerciseDetails: [
            {
                exerciseId:2,
                sets: [
                    {
                        weight:'79',
                        reps:'3'
                    },
                    {
                        weight:'23',
                        reps:'10'
                    },
                ]
            },
            {
                exerciseId:3,
                sets: [
                    {
                        weight:'12',
                        reps:'2'
                    },
                    {
                        weight:'26',
                        reps:'4'
                    },
                ]
            }
        ]
    }
]

 
 */
    const handleSetExerciseData = (newData) =>{
        setExerciseData([...exerciseData, newData])
        console.log(exerciseData);
    }

    const storeData=()=>{
        // removeExerciseFullData();
        console.log("getStoredExerciseFullData: ",getStoredExerciseFullData);
        const newExDetails =  exerciseData.exerciseDetails.map((exercise, index)=>{
            exercise.sets = exercise.sets.filter(set=>{
                return set.reps!==null && set.weight!==null;
            })
            return exercise;
        })
        if (getStoredExerciseFullData!==null) {
            const findData = getStoredExerciseFullData.find(item => item.date===currentDate);
            let updatedData=null;
            if (findData) {
                updatedData = getStoredExerciseFullData.map((item)=>{
                    if (item.date === currentDate) {
                        

                        item.exerciseDetails=newExDetails;
                        // item.exerciseDetails=exerciseData.exerciseDetails;
                    }
                    return item;
                })
            }else{
                let mergeAfterFilter = exerciseData;
                mergeAfterFilter.exerciseDetails=newExDetails;
                updatedData = [...getStoredExerciseFullData, mergeAfterFilter];
            }
            console.log("updatedData: ",updatedData);
            storeExerciseFullData(updatedData).then(()=>{
                // console.log("storedD: ", storedD);
                Alert.alert("Success","Successfully saved!")
                navigation.navigate("MyWorkout")
            });
        }else{
            console.log("exerciseData:",exerciseData);
            let mergeAfterFilter = exerciseData;
            mergeAfterFilter.exerciseDetails=newExDetails;
            storeExerciseFullData([mergeAfterFilter]).then(()=>{
                // console.log("storedD 2: ", storedD);
                Alert.alert("Success","Successfully saved!")
                navigation.navigate("MyWorkout")
            });
        }
        
        
    }

    useEffect(()=>{
        loadData();
    },[])

    // console.log("exerciseData: ", exerciseData);
    // console.log("exerciseDate: ", exerciseDate);
    // console.log("selectedExercise: ", selectedExercise);

// console.log("Selected: ", route.params.SelectedWorkout);

  return (
    <View style={styles.container}>
        {
        (loading!==false || exerciseData.length===0)?(<View><Text style={{color:"white"}}>Loading...</Text></View>):(
        <View style={{width:"100%", paddingBottom:5, flexDirection:'row', justifyContent:'space-between', borderColor:'#ddd', borderBottomWidth:1}}>
            {(exerciseDate===currentDate)&&(<View><Text style={{color:"#fff", fontSize:17}}>Today: {currentDate}</Text></View>)}
            <View>
                {(exerciseDate===currentDate)?(<TouchableOpacity onPress={()=>{storeData()}}>
                    <Text style={{backgroundColor:'green', padding:3}}>Save Data</Text>
                </TouchableOpacity>):(<Text style={{backgroundColor:'green', padding:3, fontSize:17}}>Workout Date: {exerciseDate}</Text>)}
            </View>
        </View>
        )}
        
        <ScrollView>
            {
            (loading===true || exerciseData.length===0)?(<View><ActivityIndicator/></View>):
                (
                    selectedExercise.map((selectedItem, index)=>{
                    return(
                    <View key={index}>
                        <View>
                            <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                                <Text style={{fontSize:20,fontWeight:'bold',marginLeft:10,marginTop:15,color:'#bdbdbd'}}>{selectedItem.name}</Text>
                                <FontAwesome name='ellipsis-v' style={{fontSize:20,color:'#ddd',marginTop:22}}/>
                            </View>
                            <View style={{flexDirection:'row'}}>
                            </View>
                        </View>
                        <View style={{flexDirection:'row', padding:10, justifyContent:'space-around'}}>
                            <Text style={{color:'#ddd',paddingLeft:5,paddingTop:20,fontSize:15,}}>Set</Text>
                            <Text style={{color:'#ddd',paddingLeft:25,paddingTop:20,fontSize:15}}>Previous</Text>
                            <Text style={{color:'#ddd',paddingLeft:35,paddingTop:20,fontSize:15}}>KG</Text>
                            <Text style={{color:'#ddd',paddingLeft:35,paddingTop:20,fontSize:15}}>REPS</Text>
                        </View>
                        <View>
                            {
                                exerciseData.exerciseDetails[index].sets.map((data, i)=>{
                                    return (
                                        <Sets id={i} key={i} exerciseID={selectedItem.id} handleWeight={handleWeight} handleReps={handleReps} data={data} />
                                    )
                                })
                            }
                            
                        </View>
                        
                        {(exerciseDate===currentDate)&&(<TouchableOpacity onPress={()=>{addRows(selectedItem.id)}}>
                            <Text style={{color:'#5e88eb',textAlign:'center',paddingTop:15,fontSize:18}}>Add Sets</Text>
                        </TouchableOpacity>)}
                    </View>
                    )
                })
                )
            
            }
        </ScrollView>

    </View>
  )
}





const styles = StyleSheet.create({
  container:{
    flex: 1,
    backgroundColor: '#000',
    padding:15
},
input:{
  backgroundColor:'#ddd',
  borderColor:'#000',
  marginBottom:10,
  // marginLeft:30,
  // marginRight:30,
  marginTop:30,
  alignContent:'center',
  justifyContent:'center',
  borderRadius:5,
  paddingLeft:15,
  paddingRight:15,
},
test:{
    color:'#ddd',
    paddingLeft:20,
    paddingTop:20,
    paddingBottom:10,
    fontSize:15,
    marginLeft:15
},
test2:{
    color:'#ddd',
    // padding:20,
    fontSize:15,
    marginLeft:25,
},
input2:{
    backgroundColor:'#ddd',
    borderColor:'#000',
    marginBottom:10,
    // marginLeft:30,
    // marginRight:30,
    // marginTop:30,
    alignContent:'center',
    justifyContent:'center',
    marginLeft:25,
    // marginEnd:20,
    borderRadius:5,
    // paddingLeft:15,
    // paddingRight:15,
  },

})