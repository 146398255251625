const getCurrentDate=()=>{
    var day = new Date().getDate();
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    if (day < 10) {
        day = '0' + day;
    }
    
    if (month < 10) {
        month = `0${month}`;
    }

    //Alert.alert(date + '-' + month + '-' + year);
    // You can turn it in to your desired format
    return day + '-' + month + '-' + year;//format: d-m-y;
}

const getCurrentDateCalenderStyle=()=>{
    var day = new Date().getDate();
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    if (day < 10) {
        day = '0' + day;
    }
    
    if (month < 10) {
        month = `0${month}`;
    }

    //Alert.alert(date + '-' + month + '-' + year);
    // You can turn it in to your desired format
    return year + '-' + month + '-' + day;//format: d-m-y;
}

export const formatDateCalenderStyle = (value)=>{
     //- 7 * 24 * 60 * 60 * 1000
    var day = new Date(value).getDate();
    var month = new Date(value).getMonth() + 1;
    var year = new Date(value).getFullYear();

    if (day < 10) {
        day = '0' + day;
    }
    
    if (month < 10) {
        month = `0${month}`;
    }

    return year + '-' + month + '-' + day;//format: d-m-y;
}

export const formatDate = (value)=>{
    //- 7 * 24 * 60 * 60 * 1000
   var day = new Date(value).getDate();
   var month = new Date(value).getMonth() + 1;
   var year = new Date(value).getFullYear();
 
   if (day < 10) {
        day = '0' + day;
    }

    if (month < 10) {
        month = `0${month}`;
    }
    
   return day + '-' + month + '-' + year;//format: d-m-y;
}

export const currentDate = getCurrentDate();
export const currentDateCalender = getCurrentDateCalenderStyle();
// export const formatDateCalenderStyle = getDateCalenderStyle(Date.now());


export const getDayNameByDate = (date) =>{
    const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
    var dateParts = date.split("-");
    const index = new Date(+dateParts[2],dateParts[1]-1, +dateParts[0]);
    // console.log("index: ",index);
    return weekday[index.getDay()];
}


export const formatDateTime = (date_param) =>{
    const date = new Date(date_param)
    return date.getDate()+"-"+date.getMonth()+"-"+date.getFullYear()+" "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds()
} 