import AsyncStorage from "@react-native-async-storage/async-storage"

export const storeWeightData = async(value) => {
    try {
        await AsyncStorage.setItem('weightData', JSON.stringify(value))
      } catch (e) {
        // saving error
      }
}

export const getWeightData = async() => {
    try {
        const jsonValue = await AsyncStorage.getItem('weightData')
        // console.log("jsonValue: ",jsonValue);
        return jsonValue !== null ? JSON.parse(jsonValue) : null;
      } catch(e) {
        console.log("getWeightData: ", e)
      }
      
}

export const removeWeightData = async () => {
    try {
        await AsyncStorage.removeItem('weightData')
    } catch(e) {
        // remove error
    }

    console.log('Done.')
}