import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import Login from '../Login';
import Register from '../Register';
import { NavigationContainer } from '@react-navigation/native';

const Stack = createNativeStackNavigator();

function AuthStack() {
  return (
    <NavigationContainer>
        <Stack.Navigator > 
        <Stack.Screen name='Login' component={Login} options={{headerShown:false}}/>
        <Stack.Screen name='Register' component={Register} options={{headerShown:false}}/>
        </Stack.Navigator>
    </NavigationContainer>
  )
}

export default AuthStack