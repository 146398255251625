import React, {useEffect, useState} from 'react';
import { StatusBar } from 'expo-status-bar';
import { Button, StyleSheet, Text, View, TextInput } from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
import TouchableScale from 'react-native-touchable-scale';
import AsyncStorage from '@react-native-async-storage/async-storage';

/*
 workout note structure
 {
  id:
  name:
 }

*/
export default function Template({navigation}) {

  const [ workoutNote, setWorkoutNote] = useState('');
  // console.log(workoutNote)

  const getData = async () => {
    try {
      const jsonValue = await AsyncStorage.getItem('workoutNote');
      // console.log(jsonValue);
      return jsonValue !== null ? JSON.parse(jsonValue) : null
    } catch(e) {
      // read error
    }
  
    // console.log('Done.')
  }

  const storeData = async (value) => {
    try {
      await AsyncStorage.setItem('workoutNote', JSON.stringify(value))
    } catch (e) {
      // saving error
    }
  }

  const addWorkout = async () =>{
    const workoutData = await getData();
    // console.log("add ",workoutData);

    if (workoutData!=null) {
      let newWorkout = [...workoutData, {id:workoutData[workoutData.length-1].id+1, name:workoutNote, logo:'https://static.strengthlevel.com/images/illustrations/squat-1000x1000.jpg'}];
      storeData(newWorkout);
      // console.log(newWorkout);
    }else{
      storeData([
        {
          id:1,
          name:workoutNote,
          logo:'https://static.strengthlevel.com/images/illustrations/squat-1000x1000.jpg',
        },
      ])
    }
    navigation.navigate('AddExercise');

  }


  return (
    <View style={styles.container}>
      <View style={{flexDirection:'row',justifyContent:'space-between'}}>
        <Text style={{fontSize:20,fontWeight:'bold',color:'#fff', flexDirection:'row', }}>New Workout Template</Text>
        <FontAwesome name='ellipsis-v' style={{fontSize:20,color:'#0000ff',flexDirection:'row', }}/>
      </View>
      <TextInput placeholder='Workout Note' placeholderTextColor='#ddd' style={styles.input} onChangeText={text => setWorkoutNote(text)}></TextInput>
      <TouchableScale>
        <Button title='Add Exercise' onPress={()=>{addWorkout()}} style={{paddingVertical:20}}>Add Exercise</Button>
      </TouchableScale>

    <Text style={{color:'#fff'}}>Typed: {workoutNote}</Text>
    </View>
  )
}





const styles = StyleSheet.create({
  container:{
    flex: 1,
    backgroundColor: '#000',
    padding:15
},
input:{
  backgroundColor:'#000',
  borderColor:'#fff',
  color:'#fff',
  borderWidth:0.5,
  marginBottom:10,
  // marginLeft:30,
  // marginRight:30,
  marginTop:15,
  alignContent:'center',
  justifyContent:'center',
  borderRadius:25,
  paddingLeft:15,
  paddingRight:15,
  paddingVertical:5,
  height:40,
  marginHorizontal:30,
},

})