import { StyleSheet, Text, View,TextInput, ScrollView,FlatList, Image,ImageBackground} from 'react-native';
import TouchableScale from 'react-native-touchable-scale';
import {FontAwesome} from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';



export default function Meditation({navigation}) {
    return(
        <ScrollView style={styles.container}>
            <View>
                
                <View style={{flexDirection:'row', paddingLeft:10,paddingRight:10,paddingTop:5,marginBottom:20, backgroundColor:'#000', borderRadius:5, justifyContent:'space-between'}}>
                    <FontAwesome name='search' style={{fontSize:20 ,color:'#b2b1b9',flexDirection:'row', padding:10}}/>
                    {/* <Text style={{flexDirection:'row',fontSize:20, color:'#b2b1b9',padding:10, marginRight:40}}>Adonis</Text> */}
                    <TextInput placeholder='Discover Meditation'placeholderTextColor='#b2b1b9' style={{fontSize:16,color:'#b2b1b9'}}  ></TextInput> 
                    <TouchableScale onPress={()=>{navigation.navigate('MeditationUnlock')}} style={{backgroundColor:'#ddd', borderRadius:15}}>
                        <Text style={{flexDirection:'row',fontSize:15, color:'#000',padding:10, borderRadius:5, marginTop:4}}>Unlock</Text>
                    </TouchableScale>
                </View>

                <View>
                <Image 
                style={{ height:200, borderRadius:10,marginBottom:15}}
                source={{
                    uri:'https://media.istockphoto.com/id/1313456479/photo/man-and-soul-yoga-lotus-pose-meditation-on-nebula-galaxy-background.jpg?b=1&s=170667a&w=0&k=20&c=p_EQSpHfArCOvibKe7ypoyFZERAiEFHuFx4weXiHd0g='
                }}
                />

            </View>
                    
                <View style={{backgroundColor:'#000', paddingVertical:20, borderRadius:20, borderColor:'#ddd', borderWidth:0.5, paddingBottom:12}}>
                <View style={{flexDirection:'row', justifyContent:'space-evenly'}}>
                    <View style={{backgroundColor:'#183c57' , borderRadius:15, paddingVertical:20, paddingHorizontal:32}}>
                        <FontAwesome name='circle-o' style={{fontSize:24 ,color:'orange',paddingLeft:30,marginBottom:3}}/>
                        <Text style={{color:'#ddd', justifyContent:'center', alignContent:'center',fontSize:18}}>Meditation</Text>
                    </View>
                    <View style={{backgroundColor:'#183c57' , borderRadius:15,paddingVertical:20,paddingHorizontal:50 }}>
                        <FontAwesome name="star-o" style={{fontSize:24 ,color:'orange',paddingLeft:15,marginBottom:3}}/>
                        <Text style={{color:'#ddd', justifyContent:'center', alignContent:'center',fontSize:20}}>Sleep</Text>
                    </View>
                </View>
                <View style={{flexDirection:'row', justifyContent:'space-evenly', marginVertical:10}}>
                    <View style={{backgroundColor:'#183c57' , borderRadius:15, paddingVertical:20, paddingHorizontal:45}}>
                        <FontAwesome name='list-alt' style={{fontSize:24 ,color:'orange',paddingLeft:20,marginBottom:3}}/>
                        <Text style={{color:'#ddd', justifyContent:'center', alignContent:'center',fontSize:20}}>Details</Text>
                    </View>
                    <View style={{backgroundColor:'#183c57' , borderRadius:15,paddingVertical:20,paddingHorizontal:45 }}>
                        <FontAwesome name='reddit-alien' style={{fontSize:24 ,color:'orange',paddingLeft:25,marginBottom:3}}/>
                        <Text style={{color:'#ddd', justifyContent:'center', alignContent:'center',fontSize:18}}>Wisdom</Text>
                    </View>
                </View>
                </View>

                    <Text style={{color:'#f21889', fontSize:22, textAlign:'center',marginTop:10}}>Browse Meditation By Time</Text>
                <View style={{backgroundColor:'#000',  borderRadius:20, borderColor:'#ddd', borderWidth:0.5, paddingBottom:12, marginTop:20, marginBottom:40}}>
                    <View style={{flexDirection:'row', justifyContent:'space-evenly',marginTop:10}}>
                        <View style={{backgroundColor:'#183c57' , borderRadius:15, paddingVertical:20, paddingHorizontal:30}}>
                            <Text style={{color:'#ddd', fontSize:16}}>3 min</Text>  
                        </View>
                        <View style={{backgroundColor:'#183c57' , borderRadius:15, paddingVertical:20, paddingHorizontal:30}}>
                            <Text style={{color:'#ddd', fontSize:16}}>5 min</Text>  
                        </View>
                        <View style={{backgroundColor:'#183c57' , borderRadius:15, paddingVertical:20, paddingHorizontal:30}}>
                            <Text style={{color:'#ddd', fontSize:16}}>10 min</Text>  
                        </View>
                    </View>
                    <View style={{flexDirection:'row', justifyContent:'space-evenly',marginTop:10}}>
                        <View style={{backgroundColor:'#183c57' , borderRadius:15, paddingVertical:20, paddingHorizontal:28}}>
                            <Text style={{color:'#ddd', fontSize:16}}>15 min</Text>  
                        </View>
                        <View style={{backgroundColor:'#183c57' , borderRadius:15, paddingVertical:20, paddingHorizontal:28}}>
                            <Text style={{color:'#ddd', fontSize:16}}>20 min</Text>  
                        </View>
                        <View style={{backgroundColor:'#183c57' , borderRadius:15, paddingVertical:20, paddingHorizontal:28}}>
                            <Text style={{color:'#ddd', fontSize:16}}>30 min</Text>  
                        </View>
                    </View>
                
                </View>
                </View>
            
        </ScrollView>
    )
}



const styles = StyleSheet.create({
    container:{
      flex: 1,
      backgroundColor: '#000',
      paddingTop:15,
      padding:5
    
  },
  card1_gradient:{
    // position: 'absolute',
    padding:15,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15
},

})