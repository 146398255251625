import React from 'react';
import { I18nManager,Button, StyleSheet, Text, View, TouchableOpacity,Dimensions} from 'react-native';
import Icons from 'react-native-vector-icons/MaterialCommunityIcons';
import TouchableScale from 'react-native-touchable-scale';
const screenWidth = Math.round(Dimensions.get('window').width);
import { useFonts } from 'expo-font';
const screenHeight = Math.round(Dimensions.get('window').height);



export default function CustomButton(props) {
    
  let [fontsLoad] = useFonts({
    Poppins : require('../../assets/fonts/Poppins.ttf'),
    Pattaya : require('../../assets/fonts/Pattaya.ttf')
});

if(!fontsLoad){
    return null;
}


    const {Icon, Label, Click} = props;

    return(
        <TouchableScale activeOpacity={1} activeScale={0.98} tension={100} friction={10} onPress={Click}>
        <View style={styles.button}>
        <Icons name={Icon} style={styles.buttonLeft} />
        <Text style={styles.buttonText}>{Label}</Text>
        <Icons name={I18nManager.isRTL ? "chevron-left" : "chevron-right"} style={styles.buttonRight} />
        </View>
        </TouchableScale>
        );
}

const styles = StyleSheet.create({
    button: {
        alignItems: 'center',
        flexDirection: 'row',
        height: screenHeight*0.065,
        width: '100%',
        paddingHorizontal: 85,
        position: 'relative',
        marginTop: 10
    },
    buttonText:{
        fontSize:16,
        color:"#ddd",
        fontFamily:'Poppins'
    },
    buttonLeft:{
        color: '#b3b3b3',
        position: 'absolute',
        left: 40,
        fontSize: 20,
    },
    buttonRight:{
        color: '#ddd',
        position: 'absolute',
        right: 50,
        fontSize: 20
    }
})

