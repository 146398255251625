import { StatusBar } from 'expo-status-bar';
import { Button, StyleSheet, Text, View,ScrollView,Image } from 'react-native';
import {FontAwesome} from '@expo/vector-icons';


export default function TemplateExpand({navigation}) {
  return (
    <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={styles.container}>
        <Text style={{fontSize:40, color:'#b3b3b3', padding:20}}>Legs</Text>

        <View style={{margin:15}}>
            <View style={{flexDirection:'row',justifyContent:'space-between',margin:10}}>
                <View style={{flexDirection:'row'}}>
                    <Image 
                    style={{width:50,height:50,borderRadius:50/2}}
                    source={{
                        uri:'https://static.strengthlevel.com/images/illustrations/squat-1000x1000.jpg'
                    }}/>
                    <Text style={{fontWeight:'bold',marginLeft:10,marginTop:15,color:'#b2b1b9'}}>3 x Squat (Barbell)</Text>
                    <Text style={{fontWeight:'bold',marginLeft:10,marginTop:15,color:'#b2b1b9'}}>Legs</Text>
                </View>
                <View style={styles.headerRight}>
                    <FontAwesome name='question' style={{fontSize:20,color:'#b2b1b9',marginTop:15,marginRight:10}}/>
                </View>
            </View>
        </View>

        <View style={{margin:15}}>
            <View style={{flexDirection:'row',justifyContent:'space-between',margin:10}}>
                <View style={{flexDirection:'row'}}>
                    <Image 
                    style={{width:50,height:50,borderRadius:50/2}}
                    source={{
                        uri:'https://i.pinimg.com/originals/19/77/ae/1977aec3424113ef355b1b3bca2655bc.jpg'
                    }}/>
                    <Text style={{fontWeight:'bold',marginLeft:10,marginTop:15,color:'#b2b1b9'}}>3 x Leg Extension (Machine)</Text>
                </View>
                <View style={styles.headerRight}>
                    <FontAwesome name='question' style={{fontSize:20,color:'#b2b1b9',marginTop:15,marginRight:10}}/>
                </View>
            </View>
        </View>

        <View style={{margin:15}}>
            <View style={{flexDirection:'row',justifyContent:'space-between',margin:10}}>
                <View style={{flexDirection:'row'}}>
                    <Image 
                    style={{width:50,height:50,borderRadius:50/2}}
                    source={{
                        uri:'https://www.muscleandfitness.com/wp-content/uploads/2014/02/547_B.jpg?quality=86&strip=all'
                    }}/>
                    <Text style={{fontWeight:'bold',marginLeft:10,marginTop:15,color:'#b2b1b9'}}>3 x Flat Leg Raise</Text>
                </View>
                <View style={styles.headerRight}>
                    <FontAwesome name='question' style={{fontSize:20,color:'#b2b1b9',marginTop:15,marginRight:10}}/>
                </View>
            </View>
        </View>

        <View style={{margin:15}}>
            <View style={{flexDirection:'row',justifyContent:'space-between',margin:10}}>
                <View style={{flexDirection:'row'}}>
                    <Image 
                    style={{width:50,height:50,borderRadius:50/2}}
                    source={{
                        uri:'https://www.burnthefatinnercircle.com/members/images/1793.jpg'
                    }}/>
                    <Text style={{fontWeight:'bold',marginLeft:10,marginTop:15,color:'#b2b1b9'}}>3 x Standing Calf Raise(Dumbell)</Text>
                </View>
                <View style={styles.headerRight}>
                    <FontAwesome name='question' style={{fontSize:20,color:'#b2b1b9',marginTop:15,marginRight:10}}/>
                </View>
            </View>
        </View>

    </ScrollView>
  )
}



const styles = StyleSheet.create({
    container:{
        flex: 1,
        backgroundColor: '#000',
    },
})