import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import Home from '../Home';
import Profile from '../Profile';
import Journal from '../Journal';
import Meditation from '../Meditation';
import Newsfeed from '../Newsfeed';
import Workout from '../Workout';
import Template from '../Template';
import Exercise from '../Exercise';
import TemplateExpand from '../TemplateExpand';
import Template2 from '../Template2';
import AddExercise from '../AddExercise';
import MeditationUnlock from '../MeditationUnlock';
import MyWorkout from '../MyWorkout';
import Diet from '../Diet';
import Diary from '../Diary';
import MyPosts from '../MyPosts';
import Login from '../Login';
import Register from '../Register';
import Dashboard from '../Dashboard';
import AddFood from '../AddFood';
import Screen1 from '../goals/Screen1';
import Screen2 from '../goals/Screen2';
import Screen3 from '../goals/Screen3';
import Screen4 from '../goals/Screen4';
import Screen5 from '../goals/Screen5';
import Screen6 from '../goals/Screen6';
import Screen7 from '../goals/Screen7';
import Screen8 from '../goals/Screen8';
import Screen9 from '../goals/Screen9';
import ScanBarcode from '../ScanBarcode';
import SearchFood from '../SearchFood';
import AddFood2 from '../AddFood2';
import Download from '../Download';
import MyDiary from '../MyDiary';
import SubscriptionPlans from '../SubscriptionPlans';

const Stack = createNativeStackNavigator();


function AppStackNavigation() {
  return (
    //initialRouteName='Home'
    <Stack.Navigator > 
      <Stack.Screen name='DashboardStack' component={Dashboard} options={{headerShown:false}} />
      <Stack.Screen name='Menus' component={Home} options={{headerShown:false}}/>
      <Stack.Screen name='Profile' component={Profile} options={{headerShown:false}}/>
      <Stack.Screen name='Journal' component={Journal} options={{headerShown:false}}/>
      <Stack.Screen name='Meditation' component={Meditation} options={{headerShown:false}}/>
      <Stack.Screen name='Newsfeed' component={Newsfeed} options={{headerShown:false}}/>
      <Stack.Screen name='Workout' component={Workout} options={{headerShown:false}}/>
      <Stack.Screen name='MyWorkout' component={MyWorkout} options={{headerShown:false}}/>
      <Stack.Screen name='MyDiary' component={MyDiary} options={{headerShown:false}}/>
      <Stack.Screen name='Template' component={Template} options={{headerShown:false}}/>
      <Stack.Screen name='Exercise' component={Exercise} options={{headerShown:false}}/>
      <Stack.Screen name='TemplateExpand' component={TemplateExpand} options={{headerShown:false}}/>
      <Stack.Screen name='Template2' component={Template2} options={{headerShown:false}}/>
      <Stack.Screen name='AddExercise' component={AddExercise} options={{headerShown:false}}/>
      <Stack.Screen name='MeditationUnlock' component={MeditationUnlock} options={{headerShown:false}} />
      <Stack.Screen name='Diet' component={Diet} options={{headerShown:false}}/>
      <Stack.Screen name='Diary' component={Diary} options={{headerShown:false}}/>
      <Stack.Screen name='MyPosts' component={MyPosts} options={{headerShown:false}}/>
      {/* <Stack.Screen name='DrawerNavigation' component={DrawerNavigation}/> */}
      {/* <Stack.Screen name='Login' component={Login} options={{headerShown:false}}/>
      <Stack.Screen name='Register' component={Register} options={{headerShown:false}}/> */}
      
      <Stack.Screen name='AddFood' component={AddFood} options={{headerShown:false}}/>
      <Stack.Screen name='AddFood2' component={AddFood2} options={{headerShown:false}}/>
      <Stack.Screen name='Screen1' component={Screen1} options={{headerShown:false}}/>
      <Stack.Screen name='Screen2' component={Screen2} options={{headerShown:false}}/>
      <Stack.Screen name='Screen3' component={Screen3} options={{headerShown:false}}/>
      <Stack.Screen name='Screen4' component={Screen4} options={{headerShown:false}}/>
      <Stack.Screen name='Screen5' component={Screen5} options={{headerShown:false}}/>
      <Stack.Screen name='Screen6' component={Screen6} options={{headerShown:false}}/>
      <Stack.Screen name='Screen7' component={Screen7} options={{headerShown:false}}/>
      <Stack.Screen name='Screen8' component={Screen8} options={{headerShown:false}}/>
      <Stack.Screen name='Screen9' component={Screen9} options={{headerShown:false}}/>
      <Stack.Screen name='ScanBarcode' component={ScanBarcode} options={{headerShown:false}}/>
      <Stack.Screen name='SearchFood' component={SearchFood} options={{headerShown:false}}/>
      <Stack.Screen name='SubscriptionPlans' component={SubscriptionPlans} options={{headerShown:false}}/>
      {/* <Stack.Screen name='Download' component={Download} options={{headerShown:false}}/> */}





    </Stack.Navigator>
  )
}

export default AppStackNavigation