import react,{useState} from 'react';
import { StyleSheet, Text, View,TextInput, ScrollView } from 'react-native';
import TouchableScale from 'react-native-touchable-scale';

export default function Journal({navigation}) {
    const [data, setData] = useState('');
    return(
        <View style={styles.container}>
            <ScrollView
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
            >
            <View>
                <Text style={styles.text}>Tuesday</Text>
                <Text style={styles.text2}>December 6, 2022</Text>
            </View>
            <View style={styles.vw}>
                <TouchableScale style={styles.scale} activeScale={0.98} tension={100} friction={10}>
                    <Text numberOfLines={1} style={styles.lineText}>I'M GREATFUL FOR ...</Text>
                    <TextInput style={styles.input}  onChangeText={text => setData(text)}></TextInput>
                    <TextInput style={styles.input}onChangeText={text => setData(text)}></TextInput>
                    <TextInput style={styles.input}onChangeText={text => setData(text)}></TextInput>
                </TouchableScale>
            </View>
            <View style={styles.vw}>
                <TouchableScale style={styles.scale} activeScale={0.98} tension={100} friction={10}>
                    <Text numberOfLines={1} style={styles.lineText}>WHAT WOULD MAKE TODAY GREAT?</Text>
                    <TextInput  style={styles.input}onChangeText={text => setData(text)}></TextInput>
                    <TextInput  style={styles.input}onChangeText={text => setData(text)}></TextInput>
                    <TextInput  style={styles.input}onChangeText={text => setData(text)}></TextInput>
                </TouchableScale>
            </View>
            <View style={styles.vw}>
                <TouchableScale style={styles.scale} activeScale={0.98} tension={100} friction={10}>
                    <Text numberOfLines={1} style={styles.lineText}>DAILY AFFIRMATION</Text>
                    <TextInput style={styles.input}onChangeText={text => setData(text)}></TextInput>
                    <TextInput style={styles.input}onChangeText={text => setData(text)}></TextInput>
                    <TextInput style={styles.input}onChangeText={text => setData(text)}></TextInput>
                </TouchableScale>

            </View>
                
            </ScrollView>
        </View>
    )
}



const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#000',
    },
    text :{
        fontSize:40,
        color:'#ddd',
        alignContent:'center',
        fontWeight:'bold',
        paddingTop:30,
        paddingLeft:30,

    },
    text2 :{
        fontSize:20,
        color:'#ddd',
        alignContent:'center',
        // fontWeight:'bold',
        // paddingTop:50,
        paddingLeft:30,
        paddingBottom:20
    },
    lineText:{
        fontSize:16,
        color:'#ddd',
        alignContent:'center',
        fontWeight:'bold',
        paddingTop:15,
        paddingLeft:30,
        paddingBottom:15,
    },
    scale:{
        // backgroundColor:'#9e67cf',
        marginLeft:10,
        marginRight:10,
        borderRadius:30,
        paddingBottom:20,
        backgroundColor: "#183c57",
    },
    input:{
        backgroundColor:'#000',
        borderColor:'#000',
        marginBottom:10,
        marginLeft:30,
        marginRight:30,
        alignContent:'center',
        justifyContent:'center',
        borderRadius:25,
        paddingLeft:15,
        paddingRight:15,
        color:"#ddd",
        height:30
    },
    vw:{
        marginBottom:30,
    }
})