import { Button, StyleSheet, Text, View, TextInput,ScrollView, Image } from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
import TouchableScale from 'react-native-touchable-scale';


export default function MyPosts({navigation}){
    return(
        <ScrollView style={styles.container} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
            <View style={styles.card}>
                <View style={styles.cardHeader}>
                    <View style={styles.headerLeft}>
                        <Image 
                        style={styles.userImage}
                        source={{
                            uri:'https://static.toiimg.com/thumb/msid-72095549,imgsize-52832,width-800,height-600,resizemode-75/72095549.jpg'
                        }}/>
                        <Text style={styles.userName}>Saptarshi Maitra</Text>
                    </View>
                    <View style={styles.headerRight}>
                        <FontAwesome name='ellipsis-h' style={styles.fontawesomeIcon}/>
                    </View>
                </View>
                <View style={{padding:20}}>
                    <Text style={{color:'#b2b1b9', fontSize:20}}>
                        Hello there, this is my first post and i wanna share you some big things
                    </Text>
                </View>
                    
                    <View style={styles.cardFooter}>
                        <View style={{flexDirection:'row', marginHorizontal:20}}>
                            <FontAwesome name='heart' color='grey' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#b2b1b9'}}>1,242</Text>
                        </View>
                        <View style={{flexDirection:'row' , marginHorizontal:20}}>
                            <FontAwesome name='comment' color='grey' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#b2b1b9'}}>215</Text>
                        </View>
                    </View>
                </View>

                <View style={styles.card}>
                <View style={styles.cardHeader}>
                    <View style={styles.headerLeft}>
                        <Image 
                        style={styles.userImage}
                        source={{
                            uri:'https://static.toiimg.com/thumb/msid-72095549,imgsize-52832,width-800,height-600,resizemode-75/72095549.jpg'
                        }}/>
                        <Text style={styles.userName}>Saptarshi Maitra</Text>
                    </View>
                    <View style={styles.headerRight}>
                        <FontAwesome name='ellipsis-h' style={styles.fontawesomeIcon}/>
                    </View>
                </View>
                <View style={{padding:20}}>
                    <Text style={{color:'#b2b1b9', fontSize:20}}>
                        Hello there, this is my first post and i wanna share you some big things
                    </Text>
                </View>
                    
                    <View style={styles.cardFooter}>
                        <View style={{flexDirection:'row', marginHorizontal:20}}>
                            <FontAwesome name='heart' color='grey' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#b2b1b9'}}>1,242</Text>
                        </View>
                        <View style={{flexDirection:'row' , marginHorizontal:20}}>
                            <FontAwesome name='comment' color='grey' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#b2b1b9'}}>215</Text>
                        </View>
                    </View>
                </View>


                <View style={styles.card}>
                <View style={styles.cardHeader}>
                    <View style={styles.headerLeft}>
                        <Image 
                        style={styles.userImage}
                        source={{
                            uri:'https://static.toiimg.com/thumb/msid-72095549,imgsize-52832,width-800,height-600,resizemode-75/72095549.jpg'
                        }}/>
                        <Text style={styles.userName}>Saptarshi Maitra</Text>
                    </View>
                    <View style={styles.headerRight}>
                        <FontAwesome name='ellipsis-h' style={styles.fontawesomeIcon}/>
                    </View>
                </View>
                <View style={{padding:20}}>
                    <Text style={{color:'#b2b1b9', fontSize:20}}>
                        Hello there, this is my first post and i wanna share you some big things
                    </Text>
                </View>
                    
                    <View style={styles.cardFooter}>
                        <View style={{flexDirection:'row', marginHorizontal:20}}>
                            <FontAwesome name='heart' color='grey' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#b2b1b9'}}>1,242</Text>
                        </View>
                        <View style={{flexDirection:'row' , marginHorizontal:20}}>
                            <FontAwesome name='comment' color='grey' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#b2b1b9'}}>215</Text>
                        </View>
                    </View>
                </View>


                <View style={styles.card}>
                <View style={styles.cardHeader}>
                    <View style={styles.headerLeft}>
                        <Image 
                        style={styles.userImage}
                        source={{
                            uri:'https://static.toiimg.com/thumb/msid-72095549,imgsize-52832,width-800,height-600,resizemode-75/72095549.jpg'
                        }}/>
                        <Text style={styles.userName}>Saptarshi Maitra</Text>
                    </View>
                    <View style={styles.headerRight}>
                        <FontAwesome name='ellipsis-h' style={styles.fontawesomeIcon}/>
                    </View>
                </View>
                <View style={{padding:20}}>
                    <Text style={{color:'#b2b1b9', fontSize:20}}>
                        Hello there, this is my first post and i wanna share you some big things
                    </Text>
                </View>
                    
                    <View style={styles.cardFooter}>
                        <View style={{flexDirection:'row', marginHorizontal:20}}>
                            <FontAwesome name='heart' color='grey' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#b2b1b9'}}>1,242</Text>
                        </View>
                        <View style={{flexDirection:'row' , marginHorizontal:20}}>
                            <FontAwesome name='comment' color='grey' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#b2b1b9'}}>215</Text>
                        </View>
                    </View>
                </View>


        </ScrollView>



    )
}

const styles = StyleSheet.create({
    container:{
      flex: 1,
      backgroundColor: '#000',
      padding:15
  },
  card:{
      backgroundColor:'#183c57',
      margin:10,
      padding:10,
      borderRadius:10,
  },
  cardHeader:{
      flexDirection:'row',
      justifyContent:'space-between',
  },
  headerLeft:{
      flexDirection:'row'
  },
  userImage:{
      width:50,
      height:50,
      borderRadius:50/2,
  },
  userName:{
      fontWeight:'bold',
      marginLeft:10,
      marginTop:15,
      color:'#b2b1b9'
  },
  fontawesomeIcon:{
      fontSize:20,
      color:'#b2b1b9',
      marginTop:15,
      marginRight:10
  },
  feedImage:{
      height:200,
      borderRadius:10,
      marginVertical:10,
  },
  cardFooter:{
      flexDirection:'row',
      justifyContent:'space-between',
      paddingVertical:5,
      paddingLeft:5
  },
  footerLeft:{
      flexDirection:'row',
  },
})