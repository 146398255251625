import { Button, StyleSheet, Text, View, TextInput,ScrollView,ImageBackground} from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
import TouchableScale from 'react-native-touchable-scale';


export default function Diet({navigation}) {
    return(
        <ScrollView style={styles.container}>
                <Text style={{fontSize:40, fontWeight:'bold', textAlign:'center', color:'#b3b3b3', padding:20}}>Recipies</Text>
                <Button
                title='Find A Diet For You'
                color='#419438'
                >Find Diet</Button>

            <TouchableScale style={{marginTop:20}}>
                <ImageBackground 
                style={{height:200, borderRadius:50}}
                source={{
                    uri:'https://img.freepik.com/premium-photo/big-hamburger-with-double-beef-french-fries_252907-8.jpg'
                }}
                imageStyle={{borderRadius:8, opacity:0.6}}
                >
                    <View style={{flexDirection:'column'}}>
                        <Text numberOfLines={1}  style={{color: '#b3b3b3',fontWeight: 'bold',fontSize: 21, marginTop:100, marginHorizontal:25}}>Non Vegetarian</Text>
                        <Text numberOfLines={2}  style={{color: '#ddd',fontWeight: 'bold',fontSize: 18,marginHorizontal:25, marginVertical:5}}>chicken Or Ham Burger and Fries</Text>
                        <Text numberOfLines={1} style={{color: '#ddd',fontWeight: 'bold',fontSize: 15,marginHorizontal:25}}>450 kcal || 1 serving</Text>
                    </View>
                </ImageBackground>
            </TouchableScale>

            <TouchableScale style={{marginTop:20}}>
                <ImageBackground 
                style={{height:200, borderRadius:50}}
                source={{
                    uri:'https://img.freepik.com/free-photo/chicken-wings-barbecue-sweetly-sour-sauce-picnic-summer-menu-tasty-food-top-view-flat-lay_2829-6471.jpg?w=2000'
                }}
                imageStyle={{borderRadius:8, opacity:0.6}}
                >
                    <View style={{flexDirection:'column'}}>
                        <Text numberOfLines={1}  style={{color: '#b3b3b3',fontWeight: 'bold',fontSize: 21, marginTop:100, marginHorizontal:25}}>Non Vegetarian</Text>
                        <Text numberOfLines={2}  style={{color: '#ddd',fontWeight: 'bold',fontSize: 18,marginHorizontal:25, marginVertical:5}}>chicken Or Ham Burger and Fries</Text>
                        <Text numberOfLines={1} style={{color: '#ddd',fontWeight: 'bold',fontSize: 15,marginHorizontal:25}}>450 kcal || 1 serving</Text>
                    </View>
                </ImageBackground>
            </TouchableScale>

            <TouchableScale style={{marginTop:20}}>
                <ImageBackground 
                style={{height:200, borderRadius:50}}
                source={{
                    uri:'https://i0.wp.com/images-prod.healthline.com/hlcmsresource/images/AN_images/healthy-eating-ingredients-1296x728-header.jpg?w=1155&h=1528'
                }}
                imageStyle={{borderRadius:8, opacity:0.6}}
                >
                    <View style={{flexDirection:'column'}}>
                        <Text numberOfLines={1}  style={{color: '#b3b3b3',fontWeight: 'bold',fontSize: 21, marginTop:100, marginHorizontal:25}}>Non Vegetarian</Text>
                        <Text numberOfLines={2}  style={{color: '#ddd',fontWeight: 'bold',fontSize: 18,marginHorizontal:25, marginVertical:5}}>chicken Or Ham Burger and Fries</Text>
                        <Text numberOfLines={1} style={{color: '#ddd',fontWeight: 'bold',fontSize: 15,marginHorizontal:25}}>450 kcal || 1 serving</Text>
                    </View>
                </ImageBackground>
            </TouchableScale>

            <TouchableScale style={{marginTop:20}}>
                <ImageBackground 
                style={{height:200, borderRadius:50}}
                source={{
                    uri:'https://images.everydayhealth.com/images/diet-nutrition/34da4c4e-82c3-47d7-953d-121945eada1e00-giveitup-unhealthyfood.jpg?sfvrsn=a31d8d32_0'
                }}
                imageStyle={{borderRadius:8, opacity:0.6}}
                >
                    <View style={{flexDirection:'column'}}>
                        <Text numberOfLines={1}  style={{color: '#b3b3b3',fontWeight: 'bold',fontSize: 21, marginTop:100, marginHorizontal:25}}>Non Vegetarian</Text>
                        <Text numberOfLines={2}  style={{color: '#ddd',fontWeight: 'bold',fontSize: 18,marginHorizontal:25, marginVertical:5}}>chicken Or Ham Burger and Fries</Text>
                        <Text numberOfLines={1} style={{color: '#ddd',fontWeight: 'bold',fontSize: 15,marginHorizontal:25}}>450 kcal || 1 serving</Text>
                    </View>
                </ImageBackground>
            </TouchableScale>


        </ScrollView>
    )
}


const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#000',
      padding:15
    },
})