import React, { useEffect, useState } from 'react'
import { Modal, View, Text, StatusBar, SafeAreaView, TextInput, Button, Alert } from 'react-native'
import TouchableScale from 'react-native-touchable-scale'
import {FontAwesome} from '@expo/vector-icons';
import { getWeightData, storeWeightData } from '../hooks/WeightHook';
import { currentDate } from '../Utils/DateTime';

function WeightModal({modalIsOpen, handleModalOpen}) {
    const [weightFullData, setWeightFullData] = useState(null)
    const [weightData, setWeightData] = useState(null);
    const [loading, setLoading] = useState(true);
    const handleSetWeight = (weight)=>{
        const filtered = parseFloat(weight.trim());
        
        // console.log("filtered: ", filtered," ", isNaN(filtered))
        setWeightData({date:currentDate, weight:isNaN(filtered)?0:filtered})
        const updatedFullData = weightFullData.map((item)=>{
            if (item.date===currentDate) {
                item.weight=weight;
            }
            return item;
        })
        setWeightFullData(updatedFullData);
    }

    const loadWeight = () =>{
        setLoading(true);
        getWeightData().then((data)=>{
            if (data!==null) {
                setWeightFullData(data);
                const getTodayWeight = data.find(item=>item.date===currentDate)
                console.log("getTodayWeight ",getTodayWeight)
                if (getTodayWeight) {
                    setWeightData(getTodayWeight)
                }else{
                    setWeightData({date:currentDate, weight:0})
                }
            }else{
                setWeightData({date:currentDate, weight:0})
                setWeightFullData([{date:currentDate, weight:0}])
            }
            console.log("data: ",data)
            setLoading(false);
        })
    }

    const storeWeight = () =>{
        console.log("weight.length: ", weightData)
        if (weightData.weight.toString().length>1) {
            console.log("weightFullData", weightFullData);
            // console.log("weight", weightData.weight);
            storeWeightData(weightFullData).then(()=>{
                Alert.alert('Success', "Successfully saved...");
                handleModalOpen(false);
            })
        }else{
            Alert.alert('Alert', "Minimum 2 digit required in weight value!");
        }
    }

    useEffect(()=>{
        if (modalIsOpen) {
            
            loadWeight();
        }
    }, [modalIsOpen])

  return (
        <SafeAreaView>
            <View>
                <Modal visible={modalIsOpen} animationType="slide">
                    <View style={{backgroundColor:'black', height:"100%", padding:6}}>
                        <View style={{flexDirection:'row', justifyContent:'space-between', borderBottomColor:'#bdbdbd', borderBottomWidth:1, paddingBottom:5}}>
                            <View>
                                <Text style={{fontSize:25, color:'#b2b1b9'}}>
                                    Add Weight
                                </Text>
                            </View>
                            <View>
                                <TouchableScale style={{justifyContent:'center'}} onPress={()=>{handleModalOpen(false)}}>
                                    <FontAwesome name='times' color='#b2b1b9' size={30} fontWeight={'bold'} />
                                </TouchableScale>
                            </View>
                        </View>
                        <View style={{ paddingTop:5, alignItems:'center', width:"100%" }}>
                            <Text style={{fontSize:18, color:'#bdbdbd'}}>Update today's weight</Text>
                        </View>
                        {(loading===false)&&<View style={{flexDirection:'row', justifyContent:'space-between', marginTop:10}}>
                            <View style={{width:"60%"}}>
                                <TextInput textContentType='number' value={weightData.weight} placeholder='Weight' style={{backgroundColor:'#636466', width:"100%", height:40, color:'#ddd',borderRadius:5, textAlign:'center'}} onChangeText={text => handleSetWeight(text)}></TextInput>
                            </View>
                            <View style={{width:"30%"}}>
                                <Button title='Save' onPress={()=>{storeWeight()}}></Button>
                            </View>
                        </View>}
                    </View>
                </Modal>
            </View>
        
        </SafeAreaView>
  )
}

export default WeightModal