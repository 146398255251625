import { useCallback, useEffect, useState } from 'react';
import { Button, StyleSheet, Text, View, TextInput,ScrollView, RefreshControl, Alert } from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
import TouchableScale from 'react-native-touchable-scale';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getExerciseFullData, getListedWorkout } from '../hooks/ExerciseHook';
import { currentDate } from '../Utils/DateTime';



export default function MyWorkout({navigation}) {

    const [exerciseDetails, setExerciseDetails] = useState(null);
    const [workoutList, setWorkoutList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);

    const loadData = () => {
        getExerciseFullData().then((data)=>{
            if(data) {
                const sorteddata = data.sort( 
                    (d1, d2) => (d1.date < d2.date) ? 1 : (d1.date > d2.date) ? -1 : 0);
                setExerciseDetails(sorteddata)
            };
            
        })

        getListedWorkout().then((data)=>{
            if (data){
                setWorkoutList(data);
                setLoading(false)
            }
          })
    }

    const onRefresh = useCallback(() => {
        setRefreshing(true);
        loadData();
        // Alert.alert("Data Load Refresh","data loaded")
        setRefreshing(false)
        // wait(2000).then(() => setRefreshing(false));
    }, [refreshing]);

    const getWorkoutNameByID = (exerciseID) =>{
        // console.log("workoutList: ",workoutList);
        const workout = workoutList.find(item=>item.id===exerciseID);
        return (workout)?workout.name:null;
    }

    const getWorkoutDataByID = (exerciseID) =>{
        // console.log("workoutList ID: ",workoutList);

        const workout = workoutList.find(item=>item.id===exerciseID);
        return (workout)?workout:null;
    }

    

    useEffect(()=>{
        loadData();
        
    },[])

    return(
        <ScrollView 
        style={styles.container} 
        refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
            />
          }>
            <View>
                <Text style={{fontSize:40, fontWeight:'bold', textAlign:'center', color:'#b3b3b3', padding:20}}>History</Text>
            </View>
            
            <View>
            {
                (exerciseDetails===null || exerciseDetails.length===0 || loading!==false)?(<View><Text style={{color:"white"}}>No data found...</Text></View> ):(
                    exerciseDetails.map((data, index)=>{
                        const selectedExercise = data.exerciseDetails.map((ex)=>{
                            return getWorkoutDataByID(ex.exerciseID);
                        })
                        // console.log("selectedExercise", selectedExercise);
                        return (
                        <TouchableScale style={styles.templateCard} key={index} onPress={()=>{navigation.navigate("Template2",{ExerciseDate:data.date})}}>
                            <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                                <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold",}}>List</Text>
                                <Text style={{color:'#328fa8',flexDirection:'row', fontSize:15, fontWeight:'bold', }}>{(currentDate===data.date)?"Today":data.date}</Text>
                            </View>
                            <View style={{padding:8, flexDirection:'column' }}>
                                {
                                    data.exerciseDetails.map((exercise, i)=>{
                                        const sets = exercise.sets;
                                        // console.log("sets: ", sets);
                                        return (
                                            <Text key={i} style={{color:'#b2b1b9', fontSize:14 }}>{sets.length} x {getWorkoutNameByID(exercise.exerciseID)}</Text>
                                        )
                                    })
                                }
                                
                                {/* <Text style={{color:'#b2b1b9', fontSize:14 }}>3 x Leg Extension (Machine)</Text>
                                <Text style={{color:'#b2b1b9', fontSize:14 }}>3 x Flat Leg Raise</Text>
                                <Text style={{color:'#b2b1b9', fontSize:14}}>3 x Standing Calf Raise(Dumbell)</Text> */}
                            </View>
                        </TouchableScale>
                        )
                    })
                )
            }

            </View>


        </ScrollView>
    )
}


const styles = StyleSheet.create({
    container:{
      flex: 1,
      backgroundColor: '#000',
      padding:15
  },
  templateCard:{
    borderWidth:0.6,
    borderColor:'#b5b5b5',
    marginLeft:3,
    marginRight:3,
    marginBottom:5,
    marginTop:10,
    borderRadius:10,
    padding:5,
}

})