import React, {useState} from 'react';
import { Button, StyleSheet, Text, View, TextInput,ScrollView, Image, Alert } from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
import TouchableScale from 'react-native-touchable-scale';
import { SupabaseConfig } from '../hooks/SupabaseConfig';


export default function Register({navigation}){
    const [userName, setUserName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const handleSignUp = async () =>{

        console.log("userName: ", userName)
        console.log("email: ", email)
        console.log("password: ", password)

        const { data, error } = await SupabaseConfig.auth.signUp(
            {
              email: email,
              password: password,
              options: {
                data: {
                  nick_name: userName
                }
              }
            }
          )
        if (error) {
            console.log("error: ", error);
              alert("Failed! "+ error.message)
        }else{
            console.log("Data: ", data);
            alert("Success! \nAccount created. Please check your email for verification.");
            navigation.navigate('Login')
        }
    }

    

    return(
        <View style={styles.container}>
            <View style={{margin:20, flexDirection:'row', justifyContent:'center'}}>
                <Image style={{height:200, width:200}} source={require('../../assets/Favicon_2.webp')}/>
            </View>
             <View>
             <Text style={{color:'#b3b3b3', marginLeft:'12%', marginBottom:20}}>Name</Text>
                <TextInput style={{color:'#000', backgroundColor:'#ddd',paddingHorizontal:15,paddingVertical:5, marginHorizontal:'10%', marginBottom:20, borderRadius:5}} placeholder='John Doe' onChangeText={text => setUserName(text)}></TextInput>
                <Text style={{color:'#b3b3b3', marginLeft:'12%', marginBottom:20}}>Email</Text>
                <TextInput style={{color:'#000', backgroundColor:'#ddd',paddingHorizontal:15,paddingVertical:5, marginHorizontal:'10%', marginBottom:20, borderRadius:5}} placeholder='example@example.com' onChangeText={text => setEmail(text)}></TextInput>
                <Text style={{color:'#b3b3b3', marginLeft:'12%', marginBottom:20}}>Password</Text>
                <TextInput style={{color:'#000', backgroundColor:'#ddd',paddingHorizontal:15, marginHorizontal:'10%', paddingVertical:5, borderRadius:5}} placeholder='Password' onChangeText={text => setPassword(text)}></TextInput>
                <TouchableScale>
                </TouchableScale> 
            <View style={{paddingHorizontal:'30%', paddingTop:25}}>
                <Button title='Sign Up' color='#b3b3b3' onPress={()=>{handleSignUp()}}>Sign Up</Button>          
            </View>
            <TouchableScale onPress={()=>{navigation.navigate('Login')}}>
                <Text style={{color:'#ddd', fontWeight:'bold', marginHorizontal:'15%', marginVertical:10, textAlign:'center'}}>Already Have An Account? Sign In</Text>
            </TouchableScale>

            </View>
        </View>
    )
}


const styles = StyleSheet.create({
    container:{
      flex: 1,
      backgroundColor: '#000',
      padding:15
  },
})