import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';


import DrawerNavigation from './src/screens/navigation/DrawerNavigation';

// import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";
import { useEffect, useState } from 'react';
import Download from './src/screens/Download';

import AuthStack from './src/screens/navigation/AuthStack';
import { SupabaseConfig } from './src/hooks/SupabaseConfig';



const Stack = createNativeStackNavigator();

//A Test Change for version 1.0.5


export default function App() {
  const [pwaInstall, setPwaInstall] = useState(false)
  const [auth, setAuth] = useState(null);
  const getSubscription = async () => {
   let {data: subscription, error} = await SupabaseConfig.from('subscriptions').select('*, prices(*, products(*))').in('status', ['trialing', 'active']).single();
   console.log("subscription: ", subscription)
  }
  const authCheck = async () =>{
    const { data, error } = await SupabaseConfig.auth.getSession();
    // console.log("data: ", data);
    if (error) {
      alert("Error: "+ error.message)
    }else{
      if (data.session) {
        setAuth(data);
        getSubscription();
      }
    }

    
  }
  useEffect(()=>{
    authCheck();
    

    SupabaseConfig.auth.onAuthStateChange((event, session) => {
      // console.log(event, session)
      setAuth(session);
    })
    // console.log("SupabaseConfig.auth.session(): ", SupabaseConfig.auth.getSession());

    // setPwaInstall(window.matchMedia('(display-mode: standalone)').matches)    
  }, [])

  // console.log("auth: ", auth)
  
  return (
    <>
    {/* <Stack.Navigator > 
      <Stack.Screen name='Download2' component={Download}  />
    </Stack.Navigator> */}
    {/* { (pwaInstall===false)?(<Download/>):(<NavigationContainer><DrawerNavigation /></NavigationContainer>)} */}
    {/* <NavigationContainer><DrawerNavigation /></NavigationContainer> */}
    {auth?(<NavigationContainer><DrawerNavigation auth={auth} /></NavigationContainer>):(<AuthStack />)}
    
    </>
   
   
   
    
   
   
   
  );
}





// const configuration = {
//   onUpdate: (registration) => {
//     if (registration && registration.waiting) {
//       if (window.confirm('New version available!  refresh to update your app?')) {
//         registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//         window.location.reload();
//       }
//     }
//   }
// };
// serviceWorkerRegistration.register(configuration);
