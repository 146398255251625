import registerRootComponent from 'expo/build/launch/registerRootComponent';

import App from './App';
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";

registerRootComponent(App);


const configuration = {
    onUpdate: (registration) => {
      if (registration && registration.waiting) {
        if (window.confirm('New version available!  refresh to update your app?')) {
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          window.location.reload();
        }
      }
    }
  };
  serviceWorkerRegistration.register(configuration);