import { StatusBar } from 'expo-status-bar';
import { Button, StyleSheet, Text, View } from 'react-native';

export default function Home({navigation}) {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>HomeScreen</Text>
      <StatusBar style="auto" />
      <Button
        onPress={()=>{
            navigation.navigate('Profile')
        }}
        title="Profile"
        color="#841584"
        accessibilityLabel="Learn more about this purple button"
       />
       <Button
        onPress={()=>{
            navigation.navigate('Journal')
        }}
        title="Journal"
        color="#841584"
        accessibilityLabel="Learn more about this purple button"
       />
       <Button
        onPress={()=>{
            navigation.navigate('Meditation')
        }}
        title="Meditation"
        color="#841584"
        accessibilityLabel="Learn more about this purple button"
       />
       <Button
       onPress={()=>{
           navigation.navigate('Newsfeed')
       }}
       title="Newsfeed"
       color="#841584"
       accessibilityLabel="Learn more about this purple button"
      />
      <Button
       onPress={()=>{
           navigation.navigate('Workout')
       }}
       title="Workout"
       color="#841584"
       accessibilityLabel="Learn more about this purple button"
      />
      <Button
       onPress={()=>{
           navigation.navigate('Exercise')
       }}
       title="Exercise"
       color="#841584"
       accessibilityLabel="Learn more about this purple button"
      />
      <Button
       onPress={()=>{
           navigation.navigate('Diet')
       }}
       title="Diet"
       color="#841584"
       accessibilityLabel="Learn more about this purple button"
      />
      {/* <Button
       onPress={()=>{

           navigation.navigate('DrawerNavigation')
       }}
       title="DrawerNavigation"
       color="#841584"
       accessibilityLabel="Learn more about this purple button"
      /> */}
      <Button
      onPress={()=>{
           navigation.navigate('Login')
       }}
       title="Login"
       color="#841584"
       accessibilityLabel="Learn more about this purple button"
      />
      <Button
      onPress={()=>{
          navigation.navigate('Register')
      }}
      title="Register"
      color="#841584"
      accessibilityLabel="Learn more about this purple button"
     />
     <Button
      onPress={()=>{
          navigation.navigate('Dashboard')
      }}
      title="Dashboard"
      color="#841584"
      accessibilityLabel="Learn more about this purple button"
     />
     
     <Button
      onPress={()=>{
          navigation.navigate('Diary')
      }}
      title="Diary"
      color="#841584"
      accessibilityLabel="Learn more about this purple button"
     />
     <Button
        onPress={()=>{
            navigation.navigate('MyPosts')
        }}
        title="MyPosts"
        color="#841584"
        accessibilityLabel="Learn more about this purple button"
       />
       <Button
        onPress={()=>{
            navigation.navigate('Screen1')
        }}
        title="Screen1"
        color="#841584"
        accessibilityLabel="Learn more about this purple button"
       />

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text:{
    color:'#b2b1b9'
  }
});
