import React, { useEffect, useState } from 'react'
import { ActivityIndicator, Button, StyleSheet, Text, View } from 'react-native'
import { screenHeight, screenWidth } from '../Utils/Dimensions'
// import { loadStripe } from "@stripe/stripe-js";
import Stripe from 'stripe';
import { getStripe } from '../hooks/StripeHook';
import { SupabaseConfig } from '../hooks/SupabaseConfig';
// import { stripe } from '../hooks/StripeHook';
// const stripeAdmin = new Stripe(
//   'sk_test_51MRZYrJ0xw8ZEhqlc6YqOIQSzop0SiLuubwBcuizF7G65irQdoV9mplUHEem8oMUYTzoMC9ycrm5oh0kgCJvnUtZ00g4bk9ERo'
// );
const Backend_URL = "https://api.1stmantransformation.com"



  // const stripePromise = loadStripe(
  //   'pk_test_51MaJKkBFWSkhrz7hTuOFTwGWMfTAMAGGiJSFIHAvCI3lXCftCQsc4SyapHmgRfrB47ySH8pKgq4v5RbU8oveRwcf00GvDpF4gL'
  // );

export default function SubscriptionPlans({navigation}) {
  const [loading, setLoading] = useState(true);
  const [btnOneLoading, setBtnOneLoading] = useState(false)
  const [btnTwoLoading, setBtnTwoLoading] = useState(false)
  const [subscription, setSubscription] = useState(null)

  const handleSetLoading = (value) =>{
    setLoading(value)
  }
  const stripe = getStripe(handleSetLoading);

  
  
  // console.log("Stripe : ", stripe)

  const checkSubscription = async () =>{
    setLoading(true)
    let {data: subscription, error} = await SupabaseConfig.from('subscriptions').select('*, prices(*, products(*))').in('status', ['trialing', 'active']).single();
    if (error) {
      setSubscription(null)
    }else{
      setSubscription(subscription);
    }
    setLoading(false)
  }


  
  
  
  const checkout = async (priceId, btn) =>{
    if (btn==='btnOne') {
      setBtnOneLoading(true)
    }else{
      setBtnTwoLoading(true)
    }
    console.log("priceId: ", priceId) 
    const { data, error } = await SupabaseConfig.auth.getSession();
    console.log("data: ", data.session.access_token);

    const {sessionId} = await fetch(`${Backend_URL}/create-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": `Bearer ${data.session.access_token}`
        },
        body: `priceId=${priceId}`,
      }).then((res) => res.json());
      
      stripe?.redirectToCheckout({ sessionId });

    // setLoading(true)
    // try {
    //   const prices = await stripeAdmin.prices.retrieve('price_1MoLUnJ0xw8ZEhqlNh2zmnoi');
    //   console.log("prices : ", prices)
    //   setLoading(false)
    // } catch (err) {
    //   console.error(err);
    // }

    // curl https://api.stripe.com/v1/prices/price_1MoLUnJ0xw8ZEhqlNh2zmnoi \ -u sk_test_51MRZYrJ0xw8ZEhqlc6YqOIQSzop0SiLuubwBcuizF7G65irQdoV9mplUHEem8oMUYTzoMC9ycrm5oh0kgCJvnUtZ00g4bk9ERo:

    // const session = await stripe.checkout.sessions.create({
    //   success_url: 'https://example.com/success',
    //   line_items: [
    //     {price: 'price_H5ggYwtDq4fbrJ', quantity: 1},
    //   ],
    //   mode: 'payment',
    // });

    // console.log("session: ", session)
    
    
    // console.log("Function: ", await stripe.products.retrieve('prod_NL1wmhgMPYpwHK'))
    // const list = await stripe.prices.list();
    // console.log("prices: ", list)
  }


  const checkoutToCustomerPortal = async () =>{
    const { data, error } = await SupabaseConfig.auth.getSession();
    console.log("access_token: ", data.session.access_token);

    const {portalSessionUrl} = await fetch(`${Backend_URL}/create-portal-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": `Bearer ${data.session.access_token}`
        },
      }).then((res) => res.json());
      window.location.assign(portalSessionUrl);
      
      // stripe?.redirectToCheckout({ sessionId });
  }
  
  useEffect(()=>{
    
    // console.log("stripePromise: ",stripePromise)
    if (stripe) {
      checkSubscription();
      // setLoading(false)
    }
    
  },[stripe])
  
  // if(loading!==false) return "Loading...";
  // console.log("subscription: ", subscription)

  return (
    <View style={styles.container}>
        {
        loading===true?(<ActivityIndicator/>):
        (
          subscription?(
          <View style={styles.row}>
            <View style={styles.col}>
              <View style={{justifyContent:'space-around', height:'100%'}}>
                    <View>
                        <Text style={{color:'#bdbdbd', textAlign:'center', fontSize:20, fontWeight:'bold'}}>{subscription.prices.products.name}</Text>
                        <Text style={{color:'#bdbdbd', textAlign:'center'}}>{(subscription.prices.currency).toString().toUpperCase()} {subscription.prices.unit_amount/100} / {subscription.prices.interval}</Text>
                    </View>

                </View>
            </View>
            <View style={[styles.col,{justifyContent:'center'}]}><View style={{}}><Button title='Change Plan' onPress={()=>{checkoutToCustomerPortal()}}></Button></View></View>
          </View>
            ):(
          <View style={styles.row}>
            <View style={styles.col}>
                <View style={{justifyContent:'space-around', height:'100%'}}>
                    <View>
                        <Text style={{color:'#bdbdbd', textAlign:'center', fontSize:18}}>Starter</Text>
                        <Text style={{color:'#bdbdbd', textAlign:'center'}}>$10/month</Text>
                    </View>
                    <View>
                        {btnOneLoading===true?(<ActivityIndicator/>):(<Button title='Subscribe' onPress={()=>{checkout('price_1MoLU4J0xw8ZEhqldig3ahnM', 'btnOne')}}></Button>)}
                    </View>

                </View>
            </View>
            <View style={styles.col}>
            <View style={{justifyContent:'space-around', height:'100%'}}>
                    <View>
                        <Text style={{color:'#bdbdbd', textAlign:'center', fontSize:18}}>Premium</Text>
                        <Text style={{color:'#bdbdbd', textAlign:'center'}}>$29/month</Text>
                    </View>
                    <View>
                        {btnTwoLoading===true?(<ActivityIndicator/>):(<Button title='Subscribe' onPress={()=>{checkout('price_1MoLUnJ0xw8ZEhqlNh2zmnoi', 'btnTwo')}}></Button>)}
                    </View>

                </View>
            </View>
        </View>)
        )
        }

    </View>
  )
}



const styles = StyleSheet.create({
    container:{
      flex: 1,
      backgroundColor: '#000',
      padding:15,
      width: screenWidth,

  },
  row:{
    flexDirection:'row',
    justifyContent:'space-around',
    height:screenHeight*0.22,
  },
  col:{
    width:'45%',
    backgroundColor:'#183c57',
    opacity: 0.6,
    borderRadius:10,
    padding:6,
  }

})