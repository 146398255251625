import AsyncStorage from "@react-native-async-storage/async-storage"

export const storeExerciseFullData = async(value) => {
    try {
        await AsyncStorage.setItem('exerciseData', JSON.stringify(value))
      } catch (e) {
        // saving error
      }
}

export const getExerciseFullData = async() => {
    try {
        const jsonValue = await AsyncStorage.getItem('exerciseData')
        // console.log("jsonValue: ",jsonValue);
        return jsonValue !== null ? JSON.parse(jsonValue) : null;
      } catch(e) {
        console.log("getExerciseFullData: ", e)
      }
      
}

export const removeExerciseFullData = async () => {
    try {
      await AsyncStorage.removeItem('exerciseData')
    } catch(e) {
      // remove error
    }
  
    console.log('Done.')
  }


export const getListedWorkout = async() => {
  try {
      const jsonValue = await AsyncStorage.getItem('workoutNote')
      // console.log("jsonValue: ",jsonValue);
      return jsonValue !== null ? JSON.parse(jsonValue) : null;
    } catch(e) {
      console.log("getListedWorkout: ", e)
    }
    
}

