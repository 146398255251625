import { useCallback, useEffect, useState } from 'react';
import { Button, StyleSheet, Text, View, TextInput,ScrollView, RefreshControl, Alert } from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
import TouchableScale from 'react-native-touchable-scale';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getExerciseFullData, getListedWorkout } from '../hooks/ExerciseHook';
import { currentDate, currentDateCalender, formatDate } from '../Utils/DateTime';
import { getDiaryFullDataByDate } from '../hooks/DiaryHook';
import { ActivityIndicator } from 'react-native-web';



export default function MyDiary({navigation}) {

    const [diaryDetails, setDiaryDetails] = useState(null);
    const [workoutList, setWorkoutList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);

    const groupItems = (array, property)=> {
        return array.reduce(function(groups, item) {
            var name = item[property]
            var group = groups[name] || (groups[name] = []);
            group.push(item);
            return groups;
        }, { });
    }

    const loadData = () => {
        setLoading(true)
        getDiaryFullDataByDate().then((data)=>{
            console.log("data: ", data)
            if(data) {
                
                const groupByDate =  groupItems(data, 'date');
                // console.log("groupByDate: ", groupByDate);

                // const sorteddata = groupByDate.sort( (d1, d2) => (d1.date < d2.date) ? 1 : (d1.date > d2.date) ? -1 : 0);

                const groupArrays = Object.keys(groupByDate).sort((d1, d2) => (d1 < d2) ? 1 : (d1 > d2) ? -1 : 0).map((date, i) => {
                    return {
                      date,
                      meals: groupByDate[date]
                    };
                  });
                  
                  console.log("groupArrays: ", groupArrays);

                setDiaryDetails(groupArrays)
                // console.log("sorteddata: ", sorteddata)
            };
            setLoading(false)
        })

    }

    const onRefresh = useCallback(() => {
        setRefreshing(true);
        loadData();
        // Alert.alert("Data Load Refresh","data loaded")
        setRefreshing(false)
        // wait(2000).then(() => setRefreshing(false));
    }, [refreshing]);


    const getFoodServingsCalculateByID = (serving_data, food_data) =>{
        // const findData = foodDataList.find(item=>item.id===foodID);
        if (serving_data && food_data) {
            const caloriesForServings = parseInt(food_data.serving_size.amount); //(findData.Serving).replace("gram","").trim();  //gram
            const calories = (food_data.food_nutrients.calories)//.replace("cal","").trim() //cal
            // console.log("calories: ",calories, "caloriesForServings: ", caloriesForServings)
            return Math.round(parseFloat((calories/caloriesForServings)*serving_data.amount));
        }
        return null
    }

    

    useEffect(()=>{
        loadData();
        
    },[])

    return(
        <ScrollView 
        style={styles.container} 
        refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
            />
          }>
            <View>
                <Text style={{fontSize:40, fontWeight:'bold', textAlign:'center', color:'#b3b3b3', padding:20}}>Diary History</Text>
            </View>
            
            <View>
            {
                (loading!==false)?(<ActivityIndicator/>):(
                (diaryDetails===null || diaryDetails.length===0)?(<View><Text style={{color:"white"}}>No data found...</Text></View> ):(
                    diaryDetails.map((diary, index)=>{
                        //  console.log("index: ",index, " data: ", diary)
                         var results = {
                            Breakfast:0,
                            Lunch:0,
                            Dinner:0,
                            Snacks:0
                         };
                        diary.meals.map((mealData, mi)=>{
                           const calorie =  getFoodServingsCalculateByID(mealData.serving_data, mealData.foods);
                           if(mealData.meal_type==="Breakfast"){
                                results.Breakfast = results.Breakfast+calorie
                            }
                            if(mealData.meal_type==="Lunch"){
                                results.Lunch = results.Lunch+calorie
                            }
                            if(mealData.meal_type==="Dinner"){
                                results.Dinner = results.Dinner+calorie
                            }
                            if(mealData.meal_type==="Snacks"){
                                results.Snacks = results.Snacks+calorie
                            }
                        })
                        // console.log("results: ", results)
                        // const selectedExercise = data.exerciseDetails.map((ex)=>{
                        //     return getWorkoutDataByID(ex.exerciseID);
                        // })
                        // console.log("selectedExercise", selectedExercise);
                        return (
                        <TouchableScale style={styles.templateCard} key={index} onPress={()=>{navigation.navigate("Diary",{DiaryDate:diary.date})}}>
                            <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                                <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold",}}>Calorie Data</Text>
                                <Text style={{color:'#328fa8',flexDirection:'row', fontSize:15, fontWeight:'bold', }}>{(currentDateCalender===diary.date)?"Today":formatDate(diary.date)}</Text>
                            </View>
                            <View style={{padding:8, flexDirection:'column' }}>
                                <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                                    <Text style={{color:'#b2b1b9', fontSize:15 }}>Breakfast </Text>
                                    <Text style={{color:'#b2b1b9', fontSize:15 }}>{results.Breakfast} Cal</Text>
                                </View>
                                <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                                    <Text style={{color:'#b2b1b9', fontSize:15 }}>Lunch </Text>
                                    <Text style={{color:'#b2b1b9', fontSize:15 }}>{results.Lunch} Cal</Text>
                                </View>
                                <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                                    <Text style={{color:'#b2b1b9', fontSize:15 }}>Dinner </Text>
                                    <Text style={{color:'#b2b1b9', fontSize:15 }}>{results.Dinner} Cal</Text>
                                </View>
                                <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                                    <Text style={{color:'#b2b1b9', fontSize:15 }}>Snacks </Text>
                                    <Text style={{color:'#b2b1b9', fontSize:15 }}>{results.Snacks} Cal</Text>
                                </View>
                                <View style={{flexDirection:'row', justifyContent:'space-between', borderTopColor:'#222', borderTopWidth:1, marginTop:6}}>
                                    <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:'bold' }}>Total </Text>
                                    <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:'bold' }}>{results.Breakfast + results.Lunch + results.Dinner + results.Snacks} Cal</Text>
                                </View>

                                {
                                    // diary.meals.map((meal, i)=>{
                                        
                                        // console.log("meal: ", meal);
                                        // var breakfast = 0
                                        // var lunch = 0
                                        
                                        // if (meal.meal_type==="Breakfast") {
                                        //     breakfast = breakfast+ getFoodServingsCalculateByID(meal.serving_data, meal.foods);
                                        // } 
                                        // if (meal.meal_type==="Lunch") {
                                        //     lunch = lunch+ getFoodServingsCalculateByID(meal.serving_data, meal.foods);
                                        // } 

                                        // return (
                                        //     <Text key={i} style={{color:'#b2b1b9', fontSize:14 }}> {meal.meal_type} - {getFoodServingsCalculateByID(meal.serving_data, meal.foods)} </Text>
                                        // )
                                    // })
                                }
                                
                                {/* <Text style={{color:'#b2b1b9', fontSize:14 }}>3 x Leg Extension (Machine)</Text>
                                <Text style={{color:'#b2b1b9', fontSize:14 }}>3 x Flat Leg Raise</Text>
                                <Text style={{color:'#b2b1b9', fontSize:14}}>3 x Standing Calf Raise(Dumbell)</Text> */}
                            </View>
                        </TouchableScale>
                        )
                    })
                )
                )
            }

            </View>


        </ScrollView>
    )
}


const styles = StyleSheet.create({
    container:{
      flex: 1,
      backgroundColor: '#000',
      padding:15
  },
  templateCard:{
    borderWidth:0.6,
    borderColor:'#b5b5b5',
    marginLeft:3,
    marginRight:3,
    marginBottom:5,
    marginTop:10,
    borderRadius:10,
    padding:5,
}

})