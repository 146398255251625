import { StyleSheet, Text, View,TextInput, ScrollView,SafeAreaView,Image } from 'react-native';
import TouchableScale from 'react-native-touchable-scale';
import {FontAwesome} from '@expo/vector-icons';
import { StatusBar } from 'expo-status-bar';
// import { StreamApp } from 'expo-activity-feed';



export default function Newsfeed({navigation}) {
    return(
        <ScrollView style={styles.container}>
            <View style={styles.card}>
                <View style={styles.cardHeader}>
                    <View style={styles.headerLeft}>
                        <Image 
                        style={styles.userImage}
                        source={require('../../assets/posts/1.jpg')}/>
                        <Text style={styles.userName}>1st Man</Text>
                    </View>
                    <View style={styles.headerRight}>
                        <FontAwesome name='ellipsis-h' style={styles.fontawesomeIcon}/>
                    </View>
                </View>
                <TouchableScale>
                    <Image 
                            style={styles.feedImage}
                            source={require('../../assets/posts/1.jpg')}/>
                </TouchableScale>
                    

                <View style={styles.cardFooter}>
                    <View style={styles.footerLeft}>
                        <View style={{flexDirection:'row'}}>
                            <FontAwesome name='heart' color='red' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#ddd'}}>1,242</Text>
                        </View>
                        <View style={{flexDirection:'row' , marginLeft:10}}>
                            <FontAwesome name='comment' color='grey' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#ddd'}}>215</Text>
                        </View>
                    </View>
                    <View style={styles.footerRight}>
                        <FontAwesome name='bookmark' color='grey' size={20}/>
                    </View>
                </View>
            </View>

            <View style={styles.card}>
                <View style={styles.cardHeader}>
                    <View style={styles.headerLeft}>
                        <Image 
                        style={styles.userImage}
                        source={require('../../assets/posts/2.jpg')}/>
                        <Text style={styles.userName}>kris</Text>
                    </View>
                    <View style={styles.headerRight}>
                        <FontAwesome name='ellipsis-h' style={styles.fontawesomeIcon}/>
                    </View>
                </View>
                <TouchableScale>
                    <Image 
                        style={styles.feedImage}
                        source={require('../../assets/posts/3.webp')}/>
                </TouchableScale>

                <View style={styles.cardFooter}>
                    <View style={styles.footerLeft}>
                        <View style={{flexDirection:'row'}}>
                            <FontAwesome name='heart' color='red' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#ddd'}}>11,365</Text>
                        </View>
                        <View style={{flexDirection:'row' , marginLeft:10}}>
                            <FontAwesome name='comment' color='black' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#ddd'}}>1,488</Text>
                        </View>
                    </View>
                    <View style={styles.footerRight}>
                        <FontAwesome name='bookmark' color='grey' size={20}/>
                    </View>
                </View>
            </View>




            <View style={styles.card}>
                <View style={styles.cardHeader}>
                    <View style={styles.headerLeft}>
                        <Image 
                        style={styles.userImage}
                        source={require('../../assets/posts/3.webp')}/>
                        <Text style={styles.userName}>Daniel Radcliffe</Text>
                    </View>
                    <View style={styles.headerRight}>
                        <FontAwesome name='ellipsis-h' style={styles.fontawesomeIcon}/>
                    </View>
                </View>
                <TouchableScale>
                    <Image 
                        style={styles.feedImage}
                        source={require('../../assets/posts/2.jpg')}/>
                </TouchableScale>

                <View style={styles.cardFooter}>
                    <View style={styles.footerLeft}>
                        <View style={{flexDirection:'row'}}>
                            <FontAwesome name='heart' color='red' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#ddd'}}>2,359</Text>
                        </View>
                        <View style={{flexDirection:'row' , marginLeft:10}}>
                            <FontAwesome name='comment' color='grey' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#ddd'}}>864</Text>
                        </View>
                    </View>
                    <View style={styles.footerRight}>
                        <FontAwesome name='bookmark' color='grey' size={20}/>
                    </View>
                </View>
            </View>





            <View style={styles.card}>
                <View style={styles.cardHeader}>
                    <View style={styles.headerLeft}>
                        <Image 
                        style={styles.userImage}
                        source={require('../../assets/posts/3.webp')}/>
                        <Text style={styles.userName}>Kris</Text>
                    </View>
                    <View style={styles.headerRight}>
                        <FontAwesome name='ellipsis-h' style={styles.fontawesomeIcon}/>
                    </View>
                </View>
                <TouchableScale>
                    <Image 
                        style={styles.feedImage}
                        source={require('../../assets/posts/4.jpg')}/>
                </TouchableScale>

                <View style={styles.cardFooter}>
                    <View style={styles.footerLeft}>
                        <View style={{flexDirection:'row'}}>
                            <FontAwesome name='heart' color='red' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#ddd'}}>11,365</Text>
                        </View>
                        <View style={{flexDirection:'row' , marginLeft:10}}>
                            <FontAwesome name='comment' color='black' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#ddd'}}>1,488</Text>
                        </View>
                    </View>
                    <View style={styles.footerRight}>
                        <FontAwesome name='bookmark' color='grey' size={20}/>
                    </View>
                </View>
            </View>


            
        </ScrollView>
    )
}


const styles = StyleSheet.create({
    container:{
        flex: 1,
        backgroundColor: '#000',
    },
    card:{
        backgroundColor:'#183c57',
        margin:10,
        padding:10,
        borderRadius:10,
    },
    cardHeader:{
        flexDirection:'row',
        justifyContent:'space-between',
    },
    headerLeft:{
        flexDirection:'row'
    },
    userImage:{
        width:50,
        height:50,
        borderRadius:50/2,
    },
    userName:{
        fontWeight:'bold',
        marginLeft:10,
        marginTop:15,
        color:'#ddd'
    },
    fontawesomeIcon:{
        fontSize:20,
        color:'#000',
        marginTop:15,
        marginRight:10
    },
    feedImage:{
        height:200,
        borderRadius:10,
        marginVertical:10,
    },
    cardFooter:{
        flexDirection:'row',
        justifyContent:'space-between',
        paddingVertical:5,
        paddingLeft:5
    },
    footerLeft:{
        flexDirection:'row',
    },
})