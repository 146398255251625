import { StatusBar } from 'expo-status-bar';
import { Button, StyleSheet, Text, View,ScrollView, Image, FlatList, TouchableOpacity } from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
import { useState } from 'react';


const DATA = [
  {
    name: 'Good Morning(Barbell)',
    logo: 'https://static.strengthlevel.com/images/illustrations/squat-1000x1000.jpg',
    id:1
  },
  {
    name: 'Ball Slams',
    logo: 'https://static.strengthlevel.com/images/illustrations/squat-1000x1000.jpg',
    id:2
  },{
    name: 'Front Squat',
    logo: 'https://static.strengthlevel.com/images/illustrations/squat-1000x1000.jpg',
    id:3
  },{
    name: 'Around The World',
    logo: 'https://static.strengthlevel.com/images/illustrations/squat-1000x1000.jpg',
    id:4
  },{
    name: 'Chest Press',
    logo: 'https://static.strengthlevel.com/images/illustrations/squat-1000x1000.jpg',
    id:5
  },
]


function Item({name,logo}){
  return(
    <View style={{margin:5, backgroundColor:'#183c57', borderRadius:10, paddingVertical:5}}>
            <View style={{flexDirection:'row',justifyContent:'space-between',margin:10}}>
                <View style={{flexDirection:'row'}}>
                    <Image 
                    style={{width:50,height:50,borderRadius:50/2}}
                    source={{
                        uri:logo
                    }}/>
                    <Text style={{fontWeight:'bold',marginLeft:10,marginTop:15,color:'#ddd', flexDirection:'column', fontSize:16}}>{name}</Text>
                </View>
            </View>
        </View>
    
  )
}


export default function Exercise({navigation}) {

  const [dataList, setDataList] = useState(DATA);
  
  const handleOnPress = (item) =>{
    const newItem = DATA.map((val)=>{
      if (val.id==item.id) {
        return {...val, selected:true}
      } else {
        return val;
      }
    })
    console.log(item);
  }


  return (
    
    <View showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={styles.container}>

      

   


      <Text style={{fontSize:40, color:'#b3b3b3', padding:20}}>Exercise</Text>

      <FlatList
      data = {dataList}
      renderItem={({item})=> {
        return(
          <TouchableOpacity onPress={()=>handleOnPress(item)}>
            <Item name = {item.name} logo = {item.logo}/>
          </TouchableOpacity>
        )
      
    }}
      keyExtractor={item=>item.id}
      />
    </View>

  )
}



const styles = StyleSheet.create({
  container:{
    flex: 1,
    backgroundColor: '#000',
    paddingHorizontal:5
},


})