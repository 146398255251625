import React, { useState, useEffect } from 'react';
import { Text, View, StyleSheet, Button } from 'react-native';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { useRoute } from '@react-navigation/native';

const ScanBarcode = ({navigation})  =>{
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [d,setD] = useState('')
  const route = useRoute()
  const handleBarCodeScanner = route.params.handleBarCodeScanner

  useEffect(() => {
    const getBarCodeScannerPermissions = async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    };

    getBarCodeScannerPermissions();
  }, []);

  const handleBarCodeScanned = ({ type, data }) => {
    setScanned(true);
    alert(`Bar code with type ${type} and data ${data} has been scanned!`);
    setD(data);
    handleBarCodeScanner(data)
    console.log(d)
  };

  if (hasPermission === null) {
    return <Text>Requesting for camera permission</Text>;
  }
  if (hasPermission === false) {
    return <Text>No access to camera</Text>;
  }

  return (
    <View style={styles.container}>
      <BarCodeScanner
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
        style={{width:'100%', height:500}}
      />
      {scanned && <Button title={'Go Back'} onPress={() => navigation.goBack()} />}
      {/* {scanned && <Button title={'Agaib'} onPress={() => navigation.goBack()} />} */}
    </View>
  );
}

const styles = StyleSheet.create({
    container:{
        flex: 1,
        backgroundColor: '#000',
        padding:10,
        alignItems:'center',
        justifyContent:'center'
    },

})

export default ScanBarcode;
