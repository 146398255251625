import { StatusBar } from 'expo-status-bar';
import { Button, StyleSheet, Text, View,ScrollView, Image, FlatList, TouchableOpacity } from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
import TouchableScale from 'react-native-touchable-scale';
import { useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getExerciseFullData } from '../hooks/ExerciseHook';
import { currentDate } from '../Utils/DateTime';




const DATA = [
  {
    name: 'Good Morning(Barbell)',
    logo: 'https://static.strengthlevel.com/images/illustrations/squat-1000x1000.jpg',
    id:1
  },
  {
    name: 'Ball Slams',
    logo: 'https://static.strengthlevel.com/images/illustrations/squat-1000x1000.jpg',
    id:2
  },{
    name: 'Front Squat',
    logo: 'https://static.strengthlevel.com/images/illustrations/squat-1000x1000.jpg',
    id:3
  },{
    name: 'Around The World',
    logo: 'https://static.strengthlevel.com/images/illustrations/squat-1000x1000.jpg',
    id:4
  },{
    name: 'Chest Press',
    logo: 'https://static.strengthlevel.com/images/illustrations/squat-1000x1000.jpg',
    id:5
  },
]


function Item({name,logo,selected, navigation}){
  return(
    <View style={{margin:5, backgroundColor:'#183c57', borderRadius:10, paddingVertical:5}}>

            <View style={{flexDirection:'row',justifyContent:'space-between',margin:10}} >
                <View style={{flexDirection:'row'}}>
                    <Image 
                    style={{width:50,height:50,borderRadius:50/2}}
                    source={{
                        uri:(logo)?logo:'https://static.strengthlevel.com/images/illustrations/squat-1000x1000.jpg'
                    }}/>

                      <Text style={{fontWeight:'bold',marginLeft:10,marginTop:15,color:'#ddd', flexDirection:'column'}}>{name}</Text>

                </View>
                {selected===true && ( <FontAwesome name='check-circle' size={35} color='green' />) }
            </View>
            
        </View>
    
  )
}


export default function AddExercise({navigation}) {
  const [exerciseList, setExerciseList] = useState([])
  // const [selected, setSelected] = useState([]);
  // console.log("Exercise List: ", exerciseList)
  // console.log("selected list: ", selected);
  const loadData =()=>{
    AsyncStorage.setItem('workoutNote', JSON.stringify(DATA))
    AsyncStorage.getItem('workoutNote').then((data)=>{
      var newDataArr = JSON.parse(data);
      newDataArr = newDataArr.map(v => ({...v, selected: false}))
      getExerciseFullData().then((exData)=>{
        if(exData){
          const findTodayData = exData.find(item => item.date===currentDate);
          if (findTodayData) {
            newDataArr = newDataArr.map(v=>{
              var temp = v;
              findTodayData.exerciseDetails.map((item)=>{
                  if (v.id===item.exerciseID) {
                    // console.log("item: ",item)
                    temp = {...temp, selected:true}
                  }
              });
            // console.log("temp: ",temp)
            return temp;
            })
            

            // console.log("newDataArr: ",newDataArr)
            
          }
        }
        setExerciseList(newDataArr);
      })
    })
  }

  const removeWorkoutNote = async () => {
    try {
      await AsyncStorage.removeItem('workoutNote')
    } catch(e) {
      // remove error
    }
  
    console.log('Done.')
  }

  const handleOnPress = (item) =>{
    const newItem = exerciseList.map((val)=>{
      if (val.id==item.id) {
        // setSelected([...selected, item]);
        return {...val, selected:!val.selected}
      } else {
        return val;
      }
    })
    // console.log(item);
    setExerciseList(newItem);
  }

  const handleSubmit = () =>{
    const filteredData = exerciseList.filter(val=> val.selected==true);
    // console.log(filteredData);
    navigation.navigate('Template2', {SelectedWorkout:filteredData});
  }

useEffect(()=>{
  // removeWorkoutNote();
  loadData();
},[])

  return (
    <View showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={styles.container}>
      <Text style={{fontSize:40, color:'#b3b3b3', padding:20}}>Add Exercise</Text>
      {/* <TouchableScale onPress={()=>{navigation.navigate('Template2')}}> */}
        <FlatList
        data = {exerciseList}
        renderItem={({item})=> {
        return(
          <TouchableOpacity onPress={()=>handleOnPress(item)}>
            <Item name = {item.name} logo = {item.logo} selected={item.selected}/>
          </TouchableOpacity>
            )} }
        keyExtractor={item=>item.id}
        />
      {/* </TouchableScale> */}
      <View>
        <Button title='Add Data' onPress={()=>{handleSubmit()}}> Add </Button>
      </View>
    </View>

  )
}



const styles = StyleSheet.create({
  container:{
    flex: 1,
    backgroundColor: '#000',
    paddingHorizontal:5
},

})