import React, { useEffect, useRef, useState } from "react";
import { View, TextInput, Button, FlatList, Text, ActivityIndicator } from "react-native";
import { SupabaseConfig } from "../hooks/SupabaseConfig";
import { formatDateTime } from "../Utils/DateTime";
import { screenHeight } from "../Utils/Dimensions";

function Chat() {
  const [groupId, setGroupId] = useState(1); //General Group
  const [profileData, setProfileData] = useState(null); //User Profile
  const [groupData, setGroupData] = useState(null); //User Profile

  const [message, setMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userNameloading, setUserNameLoading] = useState(false);
  const [newMessageObj, setNewMessageObj] = useState(null);

  const flatListRef = useRef(null)

  const onSend = async () => {
    if (message.length>0) {
      const { data, error } = await SupabaseConfig.from('messages')
      .insert([
        { group_id:groupId, user_id: userId, message_data: {profiles:profileData, groups:groupData, message_text:message} },
      ])

      // console.log("data insert: ", data)

      // setMessageList([...messageList, message]);
      setMessage("");
    }else{
      alert("Message box is empty!")
    }
    
  }

  

  const loadMessages = async () =>{

    const { data: { session } } = await SupabaseConfig.auth.getSession();
    setUserId(session.user.id);

    let { data: groupData } = await SupabaseConfig.from('groups').select('*').eq('id', groupId);
    // console.log("groupData: ", groupData[0]);
    setGroupData({id:groupData[0].id, name:groupData[0].name});

    let { data: profileData } = await SupabaseConfig.from('profiles').select('*').eq('id', session.user.id);
    console.log("groupData: ", profileData[0]);

    setProfileData(profileData[0]);

    let { data: messages, error } = await SupabaseConfig.from('messages').select(`
    message_id:id, message_data, user_id, created_at,
    groups (
      group_id:id,
      name
    ),
    profiles (
      user_name
    )
  `).eq('group_id', groupId)
    // console.log("messages: ", messages);
    setMessageList(messages);
    setLoading(false);
    flatListRef.current.scrollToEnd({ animated: true })
  }

  // const handleSetMessageList = (newMessageObj) =>{
  //   const temp = {...newMessageObj, profiles:{user_name:getName(newMessageObj.user_id)}};
  //   setUserNameLoading(false)
    
  //   console.log('Temp: ', temp);
  //   setMessageList(prevMessageList => [...prevMessageList, temp])
  // }


  useEffect(()=>{
    loadMessages();

    // const getName = async (user_id) =>{
    //   setUserNameLoading(true)
    //   let { data: messages, error } = await SupabaseConfig.from('profiles').select('*').eq('id', user_id)
    //   if (messages) {
    //     return messages.user_name;
    //   }
    //   return null;
    // }
    

    SupabaseConfig
    .channel('any')
    .on('postgres_changes', { 
      event: '*', schema: 'public', table: 'messages',
      filter:`group_id=eq.${groupId}` 
    },
      payload => {

        // setNewMessageObj(payload.new);
        // console.log('Change received!', payload.new);
        // handleSetMessageList(payload.new);
        
        // getName(payload.new.user_id).then((user_name)=>{console.log(user_name)})
        // const temp = {...payload.new, profiles:{user_name:getName(payload.new.user_id)}};
        
        
        // console.log('Temp: ', temp);
        setMessageList(prevMessageList => [...prevMessageList, payload.new])
        // setUserNameLoading(false)



        flatListRef.current.scrollToEnd({ animated: true })
    }).subscribe()

  }, [])

  // console.log("messageList: ", messageList)

  return (
    <View style={{flex: 1, backgroundColor:'#000'}}>
      <View style={{alignContent:'space-between', height:'100%'}}>
        <View style={{height:'80%', padding:3}}>
          {(loading===false)?(<FlatList
          ref={flatListRef}
            data={messageList}
            renderItem={({ item }) =>{
              return (
                <View style={{backgroundColor:'#d7fff9', padding:10, margin:4, borderRadius:5}}>
                  <Text style={{fontWeight:'bold', color:'#000', fontSize:18}}>{item.message_data.profiles.user_name}: </Text><Text>{item.message_data.message_text}</Text>
                  <Text style={{width:'100%', textAlign:'right'}}>Sent: {formatDateTime(item.created_at)}</Text>
                </View>
              )
            } }
            keyExtractor={(item, index) => index}
          />):(<ActivityIndicator/>)}
        </View>
        <View style={{height:'20%', paddingTop:20}}>
          <TextInput
            multiline={true}
            value={message}
            numberOfLines={4}
            onChangeText={text => setMessage(text)}
            placeholder="Enter message..."
            style={{height:100, padding:2, backgroundColor:'#fff', borderTopRightRadius:8, borderTopLeftRadius:8, fontSize:18, borderColor:'#222', borderWidth:1}}
          />
          <Button title="Send" onPress={onSend} />
        </View>
      </View>
      
      
    </View>
  )
}

export default Chat