import { StatusBar } from 'expo-status-bar';
import { Button, StyleSheet, Text, View, TextInput,Dimensions } from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
import TouchableScale from 'react-native-touchable-scale';
import * as Progress from 'react-native-progress';


const screenWidth = Math.round(Dimensions.get('window').width);
const screenHeight = Math.round(Dimensions.get('window').height);



export default function Screen7({navigation}) {
    return (
      <View style={styles.container}>
        <View>
          <View>
            <Progress.Bar progress={0.77} width={null} color={'#a7ebb9'} borderColor={'#244761'} unfilledColor={'#000'} borderRadius={0} height={7} style={{ marginVertical:10}} />
          </View>

          <View style={{ marginVertical: 20}}>
            <Text style={{ color:'#ddd', fontSize:18, fontWeight:'bold'}}>What is your baseline activity</Text>
            <Text style={{ color:'#ddd', fontSize:13,marginTop:8}}>Not including workouts-we count that seperately</Text>
          </View>

          <View>
          <TouchableScale style={styles.templateCard}>
            <View style={{ padding:5}}>
                <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold"}}>Not Very Active</Text>
                <Text style={{color:'#bdbdbd', fontSize:12}}>Spends most of the day sitting(e.g,. bankteller, desk job)</Text>
            </View>
          </TouchableScale>
          <TouchableScale style={styles.templateCard}>
            <View style={{ padding:5}}>
                <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold"}}>Lightly Active</Text>
                <Text style={{color:'#bdbdbd', fontSize:12}}>Spends a good part of the day on the feet(e.g,. teacher, salesperson)</Text>
            </View>
          </TouchableScale>
          <TouchableScale style={styles.templateCard}>
            <View style={{ padding:5}}>
                <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold"}}>Active</Text>
                <Text style={{color:'#bdbdbd', fontSize:12}}>Spends a good part of the day doing some physical activity(e.g,. food server, postal carrier)</Text>
            </View>
          </TouchableScale>
          <TouchableScale style={styles.templateCard}>
            <View style={{ padding:5}}>
                <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold"}}>Very Active</Text>
                <Text style={{color:'#bdbdbd', fontSize:12}}>Spends a good part of the day doing heavy physical activity(e.g,. bike messanger, carpenter)</Text>
            </View>
          </TouchableScale>
          
          


          </View>
        </View>
        <TouchableScale style={{padding:4,backgroundColor:'#148be3',marginVertical:5,borderRadius:25, }} onPress={()=>{navigation.navigate('Screen8')}}>
            <View style={{ padding:5}}>
                <Text style={{color:'#bdbdbd', fontSize:15, fontWeight:"bold", textAlign:'center'}}>Next</Text>
            </View>
          </TouchableScale>
      </View>
    )
}


const styles = StyleSheet.create({
    container:{
      flex: 1,
      backgroundColor: '#000',
      padding:15
  },
  templateCard:{
    padding:4,
    backgroundColor:'#102a43',
    marginVertical:5
},
})