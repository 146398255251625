import { StatusBar } from 'expo-status-bar';
import { Button, StyleSheet, Text, View,SafeAreaView,Dimensions, Image , ScrollView} from 'react-native';
const screenWidth = Math.round(Dimensions.get('window').width);
const screenHeight = Math.round(Dimensions.get('window').height);
import * as Progress from 'react-native-progress';
import { useFonts } from 'expo-font';
import {FontAwesome} from '@expo/vector-icons';
import { useEffect, useState } from 'react';

const Download = ({navigation}) => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  const [showInstall, setShowInstall] = useState(false);

  const check = ()=>{
    console.log("inside check() window:  ", window)
    window.addEventListener("beforeinstallprompt", (event) => {
      console.log("we are being triggered -- event: ", event);
      setSupportsPWA(true);
      setPromptInstall(event);
      setShowInstall(true);
    });

    navigator.serviceWorker.getRegistration().then((registration) => {
      console.log("registration: ", registration)
      if (registration) {
        const mediaQuery = window.matchMedia('(display-mode: standalone)');
        console.log("mediaQuery: ", mediaQuery)
        if (mediaQuery.matches) {
          setShowInstall(false);
        }
      }
    });

  }

  useEffect(() => {
    // const focusListener = navigation.addListener('focus', ()=>{
        // setLoading(true);
        check();
    // });
    
    // return focusListener;
  }, []);

  const onClickEvent = (evt) => {
    // evt.preventDefault();
    console.log("promptInstall: ", promptInstall);
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  // if (!supportsPWA) {
  //   return null;
  // }
  console.log("supportsPWA: ", supportsPWA)
  console.log("showInstall: ", showInstall)
  
  return (
    <View style={styles.container}>
      <View style={styles.headerProfile}>

        <Image source={{
          uri:'https://static-cdn.jtvnw.net/jtv_user_pictures/166cd1f4-0b42-48a9-a104-41e9b4ada985-profile_image-300x300.png'
            }} 
            style={styles.imageProfile} resizeMode={"cover"}/>
        <View style={{flexDirection: 'row'}}>
        <Text style={styles.text}>ADONIS</Text>

        </View>
        </View>

        <View style={{marginTop:50}}>
            {
              <Button title='Download' color='#00ff00' onPress={()=>{onClickEvent()}}></Button>
            }
        </View>






    </View>
  )
}

const styles = StyleSheet.create({
    container:{
      flex: 1,
      backgroundColor: '#000',
      padding:15,
      height:screenHeight,
  },
  headerProfile:{
    width: '100%',
    height: screenHeight*0.30,
    alignItems: 'center',
    justifyContent: 'center',
    },
  imageProfile:{
    borderRadius: 100,
    width: screenWidth*0.45,
    // height: 'auto',
    minHeight: screenWidth*0.45,
      // marginBottom: 20	
      marginTop:50,
      marginBottom:20
      },
      text:{
        color:'#ddd',
        alignContent:'center',
        justifyContent:'center',
        fontSize:30,
        color:'#ddd'
        // marginTop:20
      },
})

export default Download