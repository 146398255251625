import react,{useState, useEffect} from 'react';
import { StyleSheet, Text, View,TextInput, ScrollView,SafeAreaView,Image,Alert, Button, FlatList, ActivityIndicator,TouchableOpacity } from 'react-native';
import TouchableScale from 'react-native-touchable-scale';
import {FontAwesome} from '@expo/vector-icons';


function Item({name}){
 

    
    return(
      <View style={{margin:5, backgroundColor:'#183c57', borderRadius:10, paddingVertical:5}}>
              <View style={{flexDirection:'row',justifyContent:'space-between',margin:10}}>
                  <View style={{flexDirection:'row'}}>
                      {/* <Image 
                      style={{width:50,height:50,borderRadius:50/2}}
                      source={{
                          uri:logo
                      }}/> */}
                      <Text style={{fontWeight:'bold',marginLeft:10,marginTop:15,color:'#ddd', flexDirection:'column', fontSize:16}}>{data.title}</Text>
                  </View>
              </View>
          </View>
      
    )
  }

const SearchFood = ({navigation}) => {
    const [isLoading, setLoading] = useState(true)
    const [search, setSearch] = useState('chicken');
    const [data, setData] = useState([]);
    const [eng, setEng] = useState([]); 
    // const URL = `https://api.nal.usda.gov/fdc/v1/foods/search?query=${src}&pageSize=10&api_key=Zpyl85SdSKOmevnZcU5oP5HnadxEhRt780D9qQPw`
    // const look = async() =>{
    //     // let newUrl = URL + src
    //     fetch(URL)
    //     .then((response) => response.json())
    //     .then((json)=>setData(json))
    //     .catch((error)=>alert(error))
    //     .finally(setLoading(false)); 
    // }


    const fetchData = async ({search}) => {
        const resp = await fetch(`https://api.nal.usda.gov/fdc/v1/foods/search?query=${search}&pageSize=3&api_key=Zpyl85SdSKOmevnZcU5oP5HnadxEhRt780D9qQPw`);
        const data2 = await resp.json();
        setData(data2.foods);
        // setEng(data.foodNutrients);
        setLoading(false);
      };
      console.log(data)
      const fetchNutrition = (nutrition) => {
        // isLoading && console.log(data.foodNutrients);
        const energy = nutrition.find(item => {
          return (item.nutrientName.toLowerCase().indexOf("energy") !== -1)&& item;
        })
        const carbohydrate = nutrition.find(item => {
          return (item.nutrientName.toLowerCase().indexOf("carbohydrate") !== -1)&& item;
        })
        const fat = nutrition.find(item => {
          return (item.nutrientName.toLowerCase().indexOf("fat") !== -1)&& item;

        })
        
        return [energy, carbohydrate, fat];

      };
    
    useEffect(()=>{
        fetchData({search})
        // fetchNutrition({data})

    },[]);

   
    
    // console.log("Main food nutritins : ",data.foodNutrients)
    // console.log(eng)
    // setName(data.title)
    
  return (
    <SafeAreaView style={styles.container}> 
      <View style={{ borderWidth:0.5, marginTop:10,borderColor:'#bdbdbd', borderRadius:25, flexDirection:'row',justifyContent:'space-between', backgroundColor:'#000',marginBottom:10}}>
                <FontAwesome name='search' style={{fontSize:16 ,flexDirection:'row', padding: 15, color:'grey'}}/>
                <TextInput placeholder='Search For A Food/Barcode'placeholderTextColor='#b2b1b9' style={{fontSize:16,color:'#b2b1b9'}} onChangeText={text => setSearch(text)}></TextInput> 
                <FontAwesome name='camera' onPress={()=>{navigation.navigate('ScanBarcode')}}  style={{fontSize:16 ,flexDirection:'row', padding: 15, color:'grey', justifyContent:'flex-end', }}/>
            </View>
            
            <Button title='Search' onPress={()=>{fetchData({search})}}/>


            {isLoading ? (<ActivityIndicator/> ):( 
                <FlatList
                data = {data}
                renderItem={({item})=> {
                    // console.log(item.foodNutrients)
                    const n1 = fetchNutrition(item.foodNutrients)
                    // console.log("nutrition print: ",n1);
                    // setEng(item.foodNutrients)
                  return(
                    <View key={item.fdcId} style={{margin:5, backgroundColor:'#183c57', borderRadius:10, paddingVertical:5}}>
              <View style={{}}>
                  <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                      {/* <Image 
                      style={{width:50,height:50,borderRadius:50/2}}
                      source={{
                          uri:logo
                      }}/> */}
                      <Text style={{marginLeft:10,marginTop:15,color:'#ddd', flexDirection:'column', fontSize:18}}>{item.description}</Text>
                      
                  </View>
                  <View style={{flexDirection:"column"}}>
                    <Text style={{marginLeft:10,marginTop:15,color:'#ddd', fontSize:14}}>{n1[0].nutrientName}: {n1[0].value}</Text>
                    <Text style={{marginLeft:10,marginTop:15,color:'#ddd',  fontSize:14}}>{n1[1].nutrientName}: {n1[1].value}</Text>
                    <Text style={{marginLeft:10,marginTop:15,color:'#ddd', fontSize:14}}>{n1[2].nutrientName}: {n1[2].value}</Text>
                  </View>
              </View>
          </View>
                  )
                
              }}
                keyExtractor={item=>item.id}
                />

            )}
            
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
    container:{
        flex: 1,
        backgroundColor: '#000',
        padding:10,
    },

})

export default SearchFood