import { StatusBar } from 'expo-status-bar';
import { Button, StyleSheet, Text, View , ScrollView} from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
import TouchableScale from 'react-native-touchable-scale';


export default function Workout({navigation}) {
  return (
    <ScrollView 
    showsHorizontalScrollIndicator={false}
    showsVerticalScrollIndicator={false}
    style={styles.container}
    >
        <View>
            <Text style={{fontSize:40, fontWeight:'bold', textAlign:'center', color:'#b3b3b3', padding:20}}>Workout</Text>
            <View style={{marginLeft:20, marginRight:20}}>
                <Button style={styles.btn} onPress={()=>{navigation.navigate('AddExercise')}}
                title='Start An Empty Workout'>Start Workout</Button>
            </View>
        </View>
        
        <View style={{margin:30}}>
            <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                <Text style={{color:'#bdbdbd',flexDirection:'row', marginRight:25, fontSize:20, paddingBottom:5, fontWeight:'bold'}}>My Template</Text>
                <TouchableScale  onPress={()=>{navigation.navigate('Template')}}>
                    <FontAwesome name='plus' color='grey' size={20}  />
                </TouchableScale>
            </View>
                <Text style={{color:'#b2b1b9',flexDirection:'row', marginRight:25}}>You don't have custom templates yet. Press the + icon to create your own custom templates</Text>
        </View>

        <View>
            <TouchableScale style={styles.templateCard} onPress={()=>{navigation.navigate('TemplateExpand')}}>
                <View style={{padding:20}}>
                    <Text style={{color:'#bdbdbd', fontSize:20, fontWeight:"bold", paddingBottom:10}}>Legs</Text>
                    <Text style={{color:'#b2b1b9', fontSize:14, padding:2}}>3 x Squat (Barbell)</Text>
                    <Text style={{color:'#b2b1b9', fontSize:14, padding:2}}>3 x Leg Extension (Machine)</Text>
                    <Text style={{color:'#b2b1b9', fontSize:14, padding:2}}>3 x Flat Leg Raise</Text>
                    <Text style={{color:'#b2b1b9', fontSize:14, padding:2}}>3 x Standing Calf Raise(Dumbell)</Text>
                </View>
            </TouchableScale>

            <TouchableScale style={styles.templateCard} onPress={()=>{navigation.navigate('TemplateExpand')}}>
                <View style={{padding:20}}>
                    <Text style={{color:'#bdbdbd', fontSize:20, fontWeight:"bold", paddingBottom:10}}>Chest And Triceps</Text>
                    <Text style={{color:'#b2b1b9', fontSize:14, padding:2}}>3 x Squat (Barbell)</Text>
                    <Text style={{color:'#b2b1b9', fontSize:14, padding:2}}>3 x Leg Extension (Machine)</Text>
                    <Text style={{color:'#b2b1b9', fontSize:14, padding:2}}>3 x Flat Leg Raise</Text>
                    <Text style={{color:'#b2b1b9', fontSize:14, padding:2}}>3 x Standing Calf Raise(Dumbell)</Text>
                </View>
            </TouchableScale>

            <TouchableScale style={styles.templateCard} onPress={()=>{navigation.navigate('TemplateExpand')}}>
                <View style={{padding:20}}>
                    <Text style={{color:'#bdbdbd', fontSize:20, fontWeight:"bold", paddingBottom:10}}>Back And Biceps</Text>
                    <Text style={{color:'#b2b1b9', fontSize:14, padding:2}}>3 x Squat (Barbell)</Text>
                    <Text style={{color:'#b2b1b9', fontSize:14, padding:2}}>3 x Leg Extension (Machine)</Text>
                    <Text style={{color:'#b2b1b9', fontSize:14, padding:2}}>3 x Flat Leg Raise</Text>
                    <Text style={{color:'#b2b1b9', fontSize:14, padding:2}}>3 x Standing Calf Raise(Dumbell)</Text>
                </View>
            </TouchableScale>

            <TouchableScale style={styles.templateCard} onPress={()=>{navigation.navigate('TemplateExpand')}}>
                <View style={{padding:20}}>
                    <Text style={{color:'#bdbdbd', fontSize:20, fontWeight:"bold", paddingBottom:10}}>Hamza 5x5 - Workout A</Text>
                    <Text style={{color:'#b2b1b9', fontSize:14, padding:2}}>3 x Squat (Barbell)</Text>
                    <Text style={{color:'#b2b1b9', fontSize:14, padding:2}}>3 x Leg Extension (Machine)</Text>
                    <Text style={{color:'#b2b1b9', fontSize:14, padding:2}}>3 x Flat Leg Raise</Text>
                    <Text style={{color:'#b2b1b9', fontSize:14, padding:2}}>3 x Standing Calf Raise(Dumbell)</Text>
                </View>
            </TouchableScale>

            <TouchableScale style={styles.templateCard} onPress={()=>{navigation.navigate('TemplateExpand')}}>
                <View style={{padding:20}}>
                    <Text style={{color:'#bdbdbd', fontSize:20, fontWeight:"bold", paddingBottom:10}}>Hamza 5x5 - Workout B</Text>
                    <Text style={{color:'#b2b1b9', fontSize:14, padding:2}}>3 x Squat (Barbell)</Text>
                    <Text style={{color:'#b2b1b9', fontSize:14, padding:2}}>3 x Leg Extension (Machine)</Text>
                    <Text style={{color:'#b2b1b9', fontSize:14, padding:2}}>3 x Flat Leg Raise</Text>
                    <Text style={{color:'#b2b1b9', fontSize:14, padding:2}}>3 x Standing Calf Raise(Dumbell)</Text>
                </View>
            </TouchableScale>
        </View>


    </ScrollView>
  )
}



const styles = StyleSheet.create({
    container:{
        flex: 1,
        backgroundColor: '#000',
    },
    btn:{
        color:'#ddd',
        marginLeft:20,
        backgroundColor:'#b3b3b3'
    },
    temp:{
        color:'#b2b1b9',
        flexDirection:'row',
        marginRight:25
    },
    templateCard:{
        // borderWidth:0.2,
        // borderColor:'#b5b5b5',
        marginLeft:15,
        marginRight:15,
        marginBottom:5,
        marginTop:10,
        borderRadius:10,
        backgroundColor:'#102a43'

    }
})